<form [formGroup]="linkForm" (ngSubmit)="addLink()">
    <label for="Name">Name:</label>
    <input type="text" id="Name" formControlName="Name" placeholder="Name">
    
    <label for="ReportLink">Report Link:</label>
    <input type="text" id="ReportLink" formControlName="ReportLink" placeholder="Report Link">
    
    <button type="submit">Add Link</button>
  </form>
  
  <table>
    <thead>
      <tr>
        <th>Name</th>
        <th>Report Link</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let link of links; let i = index">
        <td>{{ link.Name }}</td>
        <td>{{ link.ReportLink }}</td>
        <td>
          <a [routerLink]="['/dashboard-report', i]">Visit</a>
        </td>
      </tr>
    </tbody>
  </table>
  