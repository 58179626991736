import { PointTerm } from './../../_models/PointTerm';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { RepositoryService } from 'src/app/services/repository.service';
import { DialogService } from 'src/app/services/dialog.service';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-points-terms',
  templateUrl: './points-terms.component.html',
  styleUrls: ['./points-terms.component.css']
})
export class PointsTermsComponent implements OnInit {

  //Vars ==================================================
  isLoading = true;
  searchText: string;
  query: string;
  pointDivisionId: string;

  //Breadcrumb
  pointBuildingIdForBreadCrumb:string;
  pointIdForBreadCrumb:string;
  unitIdForBreadCrumb:string;



  //Table ==================================================
  public displayedColumns = ['name', 'pointDivisionName', 'update', 'delete'];
  public dataSource = new MatTableDataSource<PointTerm>();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private router: Router,
    private repo: RepositoryService,
    private activeRoute: ActivatedRoute,
    private confirmDialogService: DialogService,
    public notificationsService: NotificationsService) { }

  ngOnInit(): void {
    this.pointDivisionId = this.activeRoute.snapshot.params['id'];
    localStorage.setItem('pointDivisionId',this.pointDivisionId);

    this.pointBuildingIdForBreadCrumb = localStorage.getItem('pointBuildingId');
    this.pointIdForBreadCrumb = localStorage.getItem('pointId');
    this.unitIdForBreadCrumb = localStorage.getItem('unitId');


    this.pointsTermsGet();
  }
  ngAfterViewInit(): void {
    this.applySortingAndPaging();
  }

  //Database =================================================
  public pointsTermsGet = () => {
    this.repo.getData('api/pointterms/PointTermsGet?pointDivisionId=' + this.pointDivisionId)
      .subscribe(response => {
        this.isLoading = true;
        this.dataSource.data = response as PointTerm[];
        console.log(response);
        this.isLoading = false;
      }, (error) => {
        console.log(error);
      })
  }


  public pointsTermsSearch = (name: string) => {
    this.isLoading = true;
    if (name != null) {
      this.repo.getData('api/pointterms/PointTermsSearch?name=' + name + '&pointDivisionId=' + this.pointDivisionId)
        .subscribe(response => {
          this.dataSource.data = response as PointTerm[];
          console.log(response);
          this.isLoading = false;
        }, (error) => {
          console.log(error);
        })
    }
  }

  public onDeleteClick = (id: string) => {
    const dialogRef = this.confirmDialogService.openConfirmDialog('هل انت متأكد من رغبتك في حذف البند؟');
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.repo.delete('api/pointterms/PointTermDelete?id=' + id).subscribe(response => {
          this.pointsTermsGet();
          this.notificationsService.delete('تم حذف البند بنجاح');
        }, error => {
          console.log(error)
        })

      }
    });

  }

  //Helpers ===========================================
  applySortingAndPaging(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  onKeyUp(event: any) {
    console.log(event.target.value);
    if (event.target.value == '') {
      this.pointsTermsGet();
    }
  }

  public search = (value: string) => {
    //this.dataSource.filter = value.trim().toLocaleLowerCase();
    console.log(value)
    this.pointsTermsSearch(value)
  }

}
