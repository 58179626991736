<!-- Loading Indicator -->
<div *ngIf="!unitForm"
    style="display: flex; justify-content: center; align-items: center; background: rgb(37, 104, 180);">
    <mat-progress-bar color="secondary" mode="indeterminate">
    </mat-progress-bar>
</div>

<div class="row">
    <div class="col-md-12">
        <mat-card>

            <mat-card-title>
                <div class="gradient-Header">
                    <div class="gradient-child">
                        <h3>اضافة وحدة جديدة</h3>
                    </div>
                </div>

            </mat-card-title>
            <form [formGroup]="unitForm" *ngIf="unitForm" autocomplete="off" novalidate
                (ngSubmit)="onSubmit(unitForm.value)">
                <mat-card-content>
                    <input type="hidden" formControlName="id">

                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field>
                                <input matInput type="text" placeholder="اسم الوحدة" formControlName="name" id="name">
                                <mat-error *ngIf="hasError('name', 'required')">هذا الحقل مطلوب</mat-error>
                                <mat-error *ngIf="hasError('name', 'maxlength')">الاسم اكثر من 200 حرف</mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- <div class="row">
                        <div class="col-md-12">
                            <mat-form-field>
                                <mat-label>اسم المشروع</mat-label>
                                <mat-select formControlName="project" id="projectName">
                                    <mat-option *ngFor="let project of projectsList" [value]="project.id">
                                        {{project.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div> -->


                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field>
                                <mat-label>نوع الوحدة</mat-label>
                                <mat-select formControlName="unitType" id="unitType">
                                    <mat-option *ngFor="let unitType of enumUnitTypes" [value]="unitType.value">
                                        {{ unitType.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>


                </mat-card-content>

                <mat-card-actions>


                    <div class="row">
                        <div class="col-md-12">
                            <button mat-raised-button color="primary" [disabled]="!unitForm.valid">
                                {{ isUpdating == true ? 'تعديل الوحدة' : 'اضافة الوحدة' }}
                            </button>
                        </div>
                    </div>

                    <div class="row" style="margin-top: 5px;">
                        <div class="col-md-12">
                            <button mat-flat-button color="secondary" (click)="onCancel()">عودة</button>
                        </div>
                    </div>
                </mat-card-actions>
            </form>
        </mat-card>

    </div>
</div>