import { LineDivision } from './../../_models/LineDivision';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RepositoryService } from 'src/app/services/repository.service';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-lines-divisions-form',
  templateUrl: './lines-divisions-form.component.html',
  styleUrls: ['./lines-divisions-form.component.css']
})
export class LinesDivisionsFormComponent implements OnInit {

  //Vars ====================================
  linesDivisionsForm: FormGroup;
  linesDivision: LineDivision;
  lineId: string;
  id: string;
  isUpdating: boolean = false;

  constructor(private router: Router,
    private repo: RepositoryService,
    private activeRoute: ActivatedRoute,
    public notificationsService: NotificationsService) { }
    
    ngOnInit(): void {
      this.createlinesDivisionssForm();
      this.id = this.activeRoute.snapshot.params['id'];
      console.log(this.id);
      this.lineId = this.activeRoute.snapshot.params['lineId'];
      if (this.id != null) {
        this.isUpdating = true;
        this.linesDivisionsGetById(this.id);
      }
    }


    public createlinesDivisionssForm() {
      this.linesDivisionsForm = new FormGroup({
        id: new FormControl(null),
        name: new FormControl('', [Validators.required, Validators.maxLength(200)]),
      });
    }
  
    public onSubmit = (linesDivisionsFormValue) => {
      if (this.linesDivisionsForm.valid) {
        if (this.isUpdating) {
          this.linesDivisionsUpdate(linesDivisionsFormValue);
        }
        else {
          this.linesDivisionsCreate(linesDivisionsFormValue);
        }
      }
    }
  
    private linesDivisionsCreate = (linesDivisionsFormValue) => {
      let linesDivisions: LineDivision = {
        name: linesDivisionsFormValue.name,
        lineId: this.lineId,
      }
      console.log(linesDivisions);
      this.repo.create('api/Linedivision/LineDivisionCreate', linesDivisions)
        .subscribe(response => {
          this.notificationsService.success('تمت الاضافة بنجاح!');
          this.onCancel();
          console.log(response);
        }, (error) => {
          console.log(error);
        })
    }
  
    public linesDivisionsGetById = (id: string) => {
      this.repo.getData('api/Linedivision/LineDivisionGetById?id=' + id).subscribe(response => {
        this.linesDivision = response as LineDivision
        // this.linesDivisionsForm.patchValue(this.linesDivisions);
        this.linesDivisionsForm.get('id').setValue(this.linesDivision.id);
        this.linesDivisionsForm.get('name').setValue(this.linesDivision.name);
        console.log(response);
      }, (error) => {
        console.log(error);
      })
    }
  
  
    public linesDivisionsUpdate = (linesDivisionsFormValue) => {
      let linesDivisions: LineDivision = {
        id: linesDivisionsFormValue.id,
        name: linesDivisionsFormValue.name,
        lineId: this.lineId,
      }
      console.log(linesDivisions);
      this.repo.update('api/Linedivision/LineDivisionUpdate', linesDivisions)
        .subscribe(response => {
          this.notificationsService.success('تم التعديل بنجاح!');
          this.onCancel();
          console.log(response);
        }, (error) => {
          console.log(error);
        })
    }

    //Errors ======================================
  public hasError = (controlName: string, errorName: string) => {
    return this.linesDivisionsForm.controls[controlName].hasError(errorName);
  }
  public onCancel = () => {
    this.router.navigate(['/lines-divisions-list/' + this.lineId])
  }

}
