import { UploadPdfFileDialogComponent } from './../../upload-pdf-file-dialog/upload-pdf-file-dialog.component';
import { ProjectDropDownList } from './../../_models/ProjectDropDownList';
import { ExportExcelService } from './../../services/export-excel.service';
import { UserDropDownList } from './../../_models/UserDropDownList';
import { DownloaderService } from './../../services/downloader.service';
import { Constants } from './../../Constants';
import { RequestModel } from './../../_models/RequestModel';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { RepositoryService } from 'src/app/services/repository.service';
import { DialogService } from 'src/app/services/dialog.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { FormArray, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { RequestTypeDropDownList } from 'src/app/_models/RequestTypeDropDownList';
import { environment } from 'src/environments/environment';
import * as fileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-requests-list',
  templateUrl: './requests-list.component.html',
  styleUrls: ['./requests-list.component.css']
})
export class RequestsListComponent implements OnInit {

  //Vars ==================================================
  formData: FormData;
  requestForms: FormArray = this.fb.array([]);
  isLoading = true;
  baseUrl = environment.urlAddress;
  searchText: string;
  requestTypesList: RequestTypeDropDownList[];
  usertype = localStorage.getItem('userType');
  inspectorList: UserDropDownList[];
  projectManagerList: UserDropDownList[];
  projectsList: ProjectDropDownList[];
  withoutDate: boolean;
  unResponsedchecked: boolean;

  public progress: number;
  public message: string;
  selectedFiles: FileList;

  enumResponse = Constants.enumRequestReponse;
  engineeringType = Constants.enumEngineeringType;

  dateFrom: string;
  dateTo: string;

  selectedRequestType: string;
  selectedProject: string;
  fileName = 'ExcelSheet.xlsx';
  dataForExcel = [];
  defaultSelectedProjects: string;

  dateSearchFrom: string;
  dateSearchTo: string;
  //Table ==================================================
  public displayedColumns =
    ['id', 'title', 'requestNumber', 'requestName', 'submitDate', 'dueDate',
      'contractorName', 'projectManagerName', 'projectManagerResponse',
      'inspectorName', 'inspectorResponse', 'update', 'delete'];

  public dataSource = new MatTableDataSource<RequestModel>();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;


  constructor(private repo: RepositoryService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private http: HttpClient,
    public ete: ExportExcelService,
    public datePipe: DatePipe,
    private fileService: DownloaderService,
    public notificationsService: NotificationsService,
    private confirmDialogService: DialogService,
    private notificationService: NotificationsService) { }

  ngOnInit(): void {
    console.log(this.usertype);

    this.projectsGetDropDownList();
    //subadmin - viewer
    if (this.usertype == '1' || this.usertype == '4') {
      this.projectManagersGetDropDownListForSubAdmin();
      this.inspectorsGetDropDownListForSubAdmin();
    }
    else {
      this.inspectorsGetDropDownList();
      this.projectManagersGetDropDownList();
    }
    //pm  and admin - viewer - cont
    if (this.usertype == '0' || this.usertype == '1' || this.usertype == '2' || this.usertype == '3' || this.usertype == '4') {
      this.requestTypeGetDropDownList()
    }

    this.requestsGet(false);

    this.dateFrom = this.currentDate()
    this.dateTo = this.currentDate()
  }
  ngAfterViewInit(): void {
    this.applySortingAndPaging();
  }

  exportToExcel() {

    let reportData = {
      title: 'Employee Sales Report - Jan 2020',
      data: this.dataForExcel,
      headers: Object.keys(this.dataForExcel[0])
    }

    this.ete.exportExcel(reportData);
  }

  public openDialog(requestId: string) {
    const dialogRef = this.dialog.open(UploadPdfFileDialogComponent, {
      width: '350px',
      data: { requestId: requestId }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      //this.animal = result;
    });
  }






  //Database =================================================
  public requestsGet = (showAll: boolean) => {
    this.isLoading = true;
    let userId: string = localStorage.getItem('userId');

    let url = '';
    if (showAll) {
      url = 'api/requests/requestsGet?userId=' + userId + '&userType=' + this.usertype + '&showAll=' + showAll
    }
    else {
      url = 'api/requests/requestsGet?userId=' + userId + '&userType=' + this.usertype + '&showAll=' + showAll
    }

    this.repo.getData(url)
      .subscribe(response => {
        console.log(response);
        this.dataForExcel = response as [];
        this.requestForms.clear();

        //Project Manager
        if (this.usertype == '0') {
          (response as RequestModel[]).forEach((request: RequestModel) => {
            this.requestForms.push(this.fb.group({
              id: [request.id],
              title: [request.title],
              pointName: [request.pointName],
              projectName: [request.projectName],
              engineeringType: [{ value: request.engineeringType, disabled: this.isDisabled }],
              submitLevel: [request.submitLevel],
              requestNumber: [request.requestNumber],
              requestName: [request.requestName, Validators.required],
              contractorName: [request.contractorName, Validators.required],
              submitDate: [this.datePipe.transform(request.submitDate, "yyyy-MM-dd HH:mm")],
              dueDate: [this.datePipe.transform(request.dueDate, "yyyy-MM-dd HH:mm")],
              projectManagerName: [{ value: request.projectManagerId, disabled: this.isDisabled }],
              projectManagerResponse: [request.projectManagerResponse],
              inspectorName: [{ value: request.inspectorId, disabled: this.isDisabled }],
              inspectorResponse: [{ value: request.inspectorResponse, disabled: this.isDisabled }],
              projectManagerResponseDate: [this.datePipe.transform(request.projectManagerResponseDate, "yyyy-MM-dd HH:mm")],
              inspectorResponseDate: [this.datePipe.transform(request.inspectorResponseDate, "yyyy-MM-dd HH:mm")],

              projectManagerNote: [request.projectManagerNote],
              inspectorNote: [{ value: request.inspectorNote, disabled: this.isDisabled }],
              requestFrom: [request.requestFrom],
              requestTo: [request.requestTo],
            }));
          });
        }
        //Sub admin - viewer
        if (this.usertype == '1') {
          (response as RequestModel[]).forEach((request: RequestModel) => {
            this.requestForms.push(this.fb.group({
              id: [request.id],
              title: [request.title],
              pointName: [request.pointName],
              projectName: [request.projectName],
              engineeringType: [{ value: request.engineeringType, disabled: this.isDisabled }],
              submitLevel: [request.submitLevel],
              requestNumber: [request.requestNumber],
              requestName: [request.requestName, Validators.required],
              contractorName: [request.contractorName, Validators.required],
              submitDate: [this.datePipe.transform(request.submitDate, "yyyy-MM-dd HH:mm")],
              dueDate: [this.datePipe.transform(request.dueDate, "yyyy-MM-dd HH:mm")],
              projectManagerName: [request.projectManagerId],
              projectManagerResponse: [{ value: request.projectManagerResponse, disabled: this.isDisabled }],
              inspectorName: [request.inspectorId],
              inspectorResponse: [{ value: request.inspectorResponse, disabled: this.isDisabled }],
              projectManagerResponseDate: [this.datePipe.transform(request.projectManagerResponseDate, "yyyy-MM-dd HH:mm")],
              inspectorResponseDate: [this.datePipe.transform(request.inspectorResponseDate, "yyyy-MM-dd HH:mm")],

              projectManagerNote: [{ value: request.projectManagerNote, disabled: this.isDisabled }],
              inspectorNote: [{ value: request.inspectorNote, disabled: this.isDisabled }],
              requestFrom: [request.requestFrom],
              requestTo: [request.requestTo],
            }));
          });
        }
        //Inspector
        if (this.usertype == '2') {
          (response as RequestModel[]).forEach((request: RequestModel) => {
            this.requestForms.push(this.fb.group({
              id: [request.id],
              title: [request.title],
              pointName: [request.pointName],
              projectName: [request.projectName],
              engineeringType: [{ value: request.engineeringType, disabled: this.isDisabled }],
              submitLevel: [request.submitLevel],
              requestNumber: [request.requestNumber],
              requestName: [request.requestName, Validators.required],
              contractorName: [request.contractorName, Validators.required],
              submitDate: [this.datePipe.transform(request.submitDate, "yyyy-MM-dd HH:mm")],
              dueDate: [this.datePipe.transform(request.dueDate, "yyyy-MM-dd HH:mm")],
              projectManagerName: [{ value: request.projectManagerId, disabled: this.isDisabled }],
              projectManagerResponse: [{ value: request.projectManagerResponse, disabled: this.isDisabled }],
              inspectorName: [{ value: request.inspectorId, disabled: this.isDisabled }],
              inspectorResponse: [request.inspectorResponse],
              projectManagerResponseDate: [this.datePipe.transform(request.projectManagerResponseDate, "yyyy-MM-dd HH:mm")],
              inspectorResponseDate: [this.datePipe.transform(request.inspectorResponseDate, "yyyy-MM-dd HH:mm")],


              projectManagerNote: [{ value: request.projectManagerNote, disabled: this.isDisabled }],
              inspectorNote: [request.inspectorNote],
              requestFrom: [request.requestFrom],
              requestTo: [request.requestTo],
            }));
          });
        }
        //contractir
        if (this.usertype == '3') {
          (response as RequestModel[]).forEach((request: RequestModel) => {
            this.requestForms.push(this.fb.group({
              id: [request.id],
              title: [request.title],
              pointName: [request.pointName],
              projectName: [request.projectName],
              engineeringType: [{ value: request.engineeringType, disabled: this.isDisabled }],
              submitLevel: [request.submitLevel],
              requestNumber: [request.requestNumber],
              requestName: [request.requestName, Validators.required],
              contractorName: [request.contractorName, Validators.required],
              submitDate: [this.datePipe.transform(request.submitDate, "yyyy-MM-dd HH:mm")],
              dueDate: [this.datePipe.transform(request.dueDate, "yyyy-MM-dd HH:mm")],
              projectManagerName: [{ value: request.projectManagerId, disabled: this.isDisabled }],
              projectManagerResponse: [{ value: request.projectManagerResponse, disabled: this.isDisabled }],
              inspectorName: [{ value: request.inspectorId, disabled: this.isDisabled }],
              inspectorResponse: [{ value: request.inspectorResponse, disabled: this.isDisabled }],
              projectManagerResponseDate: [this.datePipe.transform(request.projectManagerResponseDate, "yyyy-MM-dd HH:mm")],
              inspectorResponseDate: [this.datePipe.transform(request.inspectorResponseDate, "yyyy-MM-dd HH:mm")],

              projectManagerNote: [{ value: request.projectManagerNote, disabled: this.isDisabled }],
              inspectorNote: [{ value: request.inspectorNote, disabled: this.isDisabled }],
              requestFrom: [request.requestFrom],
              requestTo: [request.requestTo],
            }));
          });
        }
        //viewer
        if (this.usertype == '4') {
          (response as RequestModel[]).forEach((request: RequestModel) => {
            this.requestForms.push(this.fb.group({
              id: [request.id],
              title: [request.title],
              pointName: [request.pointName],
              projectName: [request.projectName],
              engineeringType: [{ value: request.engineeringType, disabled: this.isDisabled }],
              submitLevel: [request.submitLevel],
              requestNumber: [request.requestNumber],
              requestName: [request.requestName, Validators.required],
              contractorName: [request.contractorName, Validators.required],
              submitDate: [this.datePipe.transform(request.submitDate, "yyyy-MM-dd HH:mm")],
              dueDate: [this.datePipe.transform(request.dueDate, "yyyy-MM-dd HH:mm")],
              projectManagerName: [{ value: request.projectManagerId, disabled: this.isDisabled }],
              projectManagerResponse: [{ value: request.projectManagerResponse, disabled: this.isDisabled }],
              inspectorName: [{ value: request.inspectorId, disabled: this.isDisabled }],
              inspectorResponse: [{ value: request.inspectorResponse, disabled: this.isDisabled }],
              projectManagerResponseDate: [this.datePipe.transform(request.projectManagerResponseDate, "yyyy-MM-dd HH:mm")],
              inspectorResponseDate: [this.datePipe.transform(request.inspectorResponseDate, "yyyy-MM-dd HH:mm")],

              projectManagerNote: [{ value: request.projectManagerNote, disabled: this.isDisabled }],
              inspectorNote: [{ value: request.inspectorNote, disabled: this.isDisabled }],
              requestFrom: [request.requestFrom],
              requestTo: [request.requestTo],
            }));
          });
        }
        //admin
        if (this.usertype == '5') {
          (response as RequestModel[]).forEach((request: RequestModel) => {
            this.requestForms.push(this.fb.group({
              id: [request.id],
              title: [request.title],
              pointName: [request.pointName],
              projectName: [request.projectName],
              engineeringType: [{ value: request.engineeringType, disabled: this.isDisabled }],
              submitLevel: [request.submitLevel],
              requestNumber: [request.requestNumber],
              requestName: [request.requestName, Validators.required],
              contractorName: [request.contractorName, Validators.required],
              submitDate: [this.datePipe.transform(request.submitDate, "yyyy-MM-dd HH:mm")],
              dueDate: [this.datePipe.transform(request.dueDate, "yyyy-MM-dd HH:mm")],
              projectManagerName: [{ value: request.projectManagerId, disabled: this.isDisabled }],
              projectManagerResponse: [{ value: request.projectManagerResponse, disabled: this.isDisabled }],
              inspectorName: [{ value: request.inspectorId, disabled: this.isDisabled }],
              inspectorResponse: [{ value: request.inspectorResponse, disabled: this.isDisabled }],
              projectManagerResponseDate: [this.datePipe.transform(request.projectManagerResponseDate, "yyyy-MM-dd HH:mm")],
              inspectorResponseDate: [this.datePipe.transform(request.inspectorResponseDate, "yyyy-MM-dd HH:mm")],

              projectManagerNote: [{ value: request.projectManagerNote, disabled: this.isDisabled }],
              inspectorNote: [{ value: request.inspectorNote, disabled: this.isDisabled }],
              requestFrom: [request.requestFrom],
              requestTo: [request.requestTo],
            }));
          });
        }

        this.isLoading = false;
      }, (error) => {
        console.log(error);
      })
  }

  isDisabled(): boolean {
    if (this.usertype == '0') {
      return false;
    }
    return true;
  }


  recordSubmit(fg: FormGroup) {
    //console.log(fg.value.id);

    console.log(fg.getRawValue().projectManagerResponse);

    //Prevent inspector from adding response after pm response
    if (fg.getRawValue().projectManagerResponse != null && this.usertype == '2') {
      this.notificationService.delete('قام مدير المشروع بالرد مسبقا');
      return;
    }

    if (fg.value.projectManagerName == null) {
      fg.value.projectManagerName = '0';
    }
    if (fg.value.inspectorName == null) {
      fg.value.inspectorName = '0';
    }
    if (fg.value.projectManagerResponse == null) {
      fg.value.projectManagerResponse = '-1';
    }
    if (fg.value.inspectorResponse == null) {
      fg.value.inspectorResponse = '-1';
    }
    console.log('pm response is', fg.value.projectManagerResponse);
    this.requestInlineUpdate(fg.value.id, fg.value.projectManagerName,
      fg.value.projectManagerResponse, fg.value.inspectorName,
      fg.value.inspectorResponse, fg.getRawValue().projectManagerNote, fg.getRawValue().inspectorNote);

  }

  directDownload() {

  }

  download(fg: FormGroup) {
    console.log(fg.value.id);
    this.fileService.downloadFile(fg.value.id).subscribe((response: any) => { //when you use stricter type checking
      let blob: any = new Blob([response], { type: 'application/pdf; charset=utf-8' });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
      //window.location.href = response.url;
      fileSaver.saveAs(blob, fg.value.id + '.pdf');
      //}), error => console.log('Error downloading the file'),
    }), (error: any) => console.log('Error downloading the file'), //when you use stricter type checking
      () => console.info('File downloaded successfully');

  }

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);

  }

  public requestInlineUpdate = (requestId: string, projectManagerId: string = '0'
    , projectManagerResponse: string = '-1', inspectorId: string = '0', inspectorResponse: string = '-1', pmNote: string = '', inspectorNote: string = '') => {
    this.isLoading = true;
    this.repo.getData('api/requests/requestInlineUpdate?requestId=' + requestId +
      '&projectManagerId=' + projectManagerId + '&projectManagerResponse=' + projectManagerResponse
      + '&inspectorId=' + inspectorId + '&inspectorResponse=' + inspectorResponse + '&pmNote=' + pmNote + '&inspectorNote=' + inspectorNote)

      .subscribe(response => {
        this.notificationService.success('تم الحفظ بنجاح!');
        this.isLoading = false;
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }


  public requestTypeGetDropDownList = () => {
    this.repo.getData('api/requestTypes/requestTypesGetDropDownList?userId=' + localStorage.getItem('userId'))
      .subscribe(response => {
        this.requestTypesList = response as RequestTypeDropDownList[]
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }


  public requestTypeGetDropDownListByProjectId = (projectId: string) => {
    this.repo.getData('api/requestTypes/RequestTypesGetDropDownListByProjectId?projectId=' + projectId)
      .subscribe(response => {
        this.requestTypesList = response as RequestTypeDropDownList[]
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }

  public projectManagersGetDropDownList = () => {
    this.repo.getData('api/users/projectManagersGetDropDownList')
      .subscribe(response => {
        this.projectManagerList = response as UserDropDownList[]

        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }


  public projectsGetDropDownList = () => {
    let userId: string = localStorage.getItem('userId');
    this.repo.getData('api/projects/ProjectsGetDropDownListByUserId?userId=' + userId + '&userType=' + this.usertype)
      .subscribe(response => {
        this.projectsList = response as ProjectDropDownList[]
        //this.defaultSelectedProjects = this.projectsList[0].id;
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }

  public inspectorsGetDropDownList = () => {
    this.repo.getData('api/users/inspectorsGetDropDownList')
      .subscribe(response => {
        this.inspectorList = response as UserDropDownList[]
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }

  public inspectorsGetDropDownListForSubAdmin() {
    let userId: string = localStorage.getItem('userId');
    this.repo.getData('api/users/inspectorsGetDropDownListForSubAdmin?subAdminId=' + userId)
      .subscribe(response => {
        this.inspectorList = response as UserDropDownList[]
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }


  public projectManagersGetDropDownListForSubAdmin() {
    let userId: string = localStorage.getItem('userId');
    this.repo.getData('api/users/projectManagersGetDropDownListForSubAdmin?subAdminId=' + userId)
      .subscribe(response => {
        this.projectManagerList = response as UserDropDownList[]
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }

  currentDate() {
    const currentDate = new Date();
    return currentDate.toISOString().split('T')[0];
  }

  public getAllUnResponsedRequests(isChecked: boolean) {

    if (this.dateSearchFrom == null) {
      this.dateSearchFrom = this.datePipe.transform(new Date(), "yyyy-MM-dd")
    }
    if (this.dateSearchTo == null) {
      this.dateSearchTo = this.datePipe.transform(new Date(), "yyyy-MM-dd")
    }
    let userId = localStorage.getItem('userId');
    console.log(isChecked);
    this.isLoading = true;
    if (isChecked) {
      this.repo.getData(`api/requests/GetAllUnResponsedRequests?userId=${userId}&userType=${this.usertype}&dateFrom=${this.dateSearchFrom}&dateTo=${this.dateSearchTo}&projectName=${this.selectedProject}`).subscribe(response => {
        this.requestForms.clear();
        //Project Manager
        if (this.usertype == '0') {
          (response as RequestModel[]).forEach((request: RequestModel) => {
            this.requestForms.push(this.fb.group({
              id: [request.id],
              title: [request.title],
              pointName: [request.pointName],
              projectName: [request.projectName],
              engineeringType: [{ value: request.engineeringType, disabled: this.isDisabled }],
              submitLevel: [request.submitLevel],
              requestNumber: [request.requestNumber],
              requestName: [request.requestName, Validators.required],
              contractorName: [request.contractorName, Validators.required],
              submitDate: [this.datePipe.transform(request.submitDate, "yyyy-MM-dd HH:mm")],
              dueDate: [this.datePipe.transform(request.dueDate, "yyyy-MM-dd HH:mm")],
              projectManagerName: [{ value: request.projectManagerId, disabled: this.isDisabled }],
              projectManagerResponse: [request.projectManagerResponse],
              inspectorName: [{ value: request.inspectorId, disabled: this.isDisabled }],
              inspectorResponse: [{ value: request.inspectorResponse, disabled: this.isDisabled }],
              projectManagerResponseDate: [this.datePipe.transform(request.projectManagerResponseDate, "yyyy-MM-dd HH:mm")],
              inspectorResponseDate: [this.datePipe.transform(request.inspectorResponseDate, "yyyy-MM-dd HH:mm")],

              projectManagerNote: [request.projectManagerNote],
              inspectorNote: [{ value: request.inspectorNote, disabled: this.isDisabled }],
              requestFrom: [request.requestFrom],
              requestTo: [request.requestTo],
            }));
          });
        }
        //subadmin
        if (this.usertype == '1') {
          (response as RequestModel[]).forEach((request: RequestModel) => {
            this.requestForms.push(this.fb.group({
              id: [request.id],
              title: [request.title],
              pointName: [request.pointName],
              projectName: [request.projectName],
              engineeringType: [{ value: request.engineeringType, disabled: this.isDisabled }],
              submitLevel: [request.submitLevel],
              requestNumber: [request.requestNumber],
              requestName: [request.requestName, Validators.required],
              contractorName: [request.contractorName, Validators.required],
              submitDate: [this.datePipe.transform(request.submitDate, "yyyy-MM-dd HH:mm")],
              dueDate: [this.datePipe.transform(request.dueDate, "yyyy-MM-dd HH:mm")],
              projectManagerName: [request.projectManagerId],
              projectManagerResponse: [{ value: request.projectManagerResponse, disabled: this.isDisabled }],
              inspectorName: [request.inspectorId],
              inspectorResponse: [{ value: request.inspectorResponse, disabled: this.isDisabled }],
              projectManagerResponseDate: [this.datePipe.transform(request.projectManagerResponseDate, "yyyy-MM-dd HH:mm")],
              inspectorResponseDate: [this.datePipe.transform(request.inspectorResponseDate, "yyyy-MM-dd HH:mm")],

              projectManagerNote: [{ value: request.projectManagerNote, disabled: this.isDisabled }],
              inspectorNote: [{ value: request.inspectorNote, disabled: this.isDisabled }],
              requestFrom: [request.requestFrom],
              requestTo: [request.requestTo],
            }));
          });
        }

        //Inspector
        if (this.usertype == '2') {
          (response as RequestModel[]).forEach((request: RequestModel) => {
            this.requestForms.push(this.fb.group({
              id: [request.id],
              title: [request.title],
              pointName: [request.pointName],
              projectName: [request.projectName],
              engineeringType: [{ value: request.engineeringType, disabled: this.isDisabled }],
              submitLevel: [request.submitLevel],
              requestNumber: [request.requestNumber],
              requestName: [request.requestName, Validators.required],
              contractorName: [request.contractorName, Validators.required],
              submitDate: [this.datePipe.transform(request.submitDate, "yyyy-MM-dd HH:mm")],
              dueDate: [this.datePipe.transform(request.dueDate, "yyyy-MM-dd HH:mm")],
              projectManagerName: [{ value: request.projectManagerId, disabled: this.isDisabled }],
              projectManagerResponse: [{ value: request.projectManagerResponse, disabled: this.isDisabled }],
              inspectorName: [{ value: request.inspectorId, disabled: this.isDisabled }],
              inspectorResponse: [request.inspectorResponse],
              projectManagerResponseDate: [this.datePipe.transform(request.projectManagerResponseDate, "yyyy-MM-dd HH:mm")],
              inspectorResponseDate: [this.datePipe.transform(request.inspectorResponseDate, "yyyy-MM-dd HH:mm")],

              projectManagerNote: [{ value: request.projectManagerNote, disabled: this.isDisabled }],
              inspectorNote: [request.inspectorNote],
              requestFrom: [request.requestFrom],
              requestTo: [request.requestTo],
            }));
          });
        }

         //contractir
         if (this.usertype == '3') {
          (response as RequestModel[]).forEach((request: RequestModel) => {
            this.requestForms.push(this.fb.group({
              id: [request.id],
              title: [request.title],
              pointName: [request.pointName],
              projectName: [request.projectName],
              engineeringType: [{ value: request.engineeringType, disabled: this.isDisabled }],
              submitLevel: [request.submitLevel],
              requestNumber: [request.requestNumber],
              requestName: [request.requestName, Validators.required],
              contractorName: [request.contractorName, Validators.required],
              submitDate: [this.datePipe.transform(request.submitDate, "yyyy-MM-dd HH:mm")],
              dueDate: [this.datePipe.transform(request.dueDate, "yyyy-MM-dd HH:mm")],
              projectManagerName: [{ value: request.projectManagerId, disabled: this.isDisabled }],
              projectManagerResponse: [{ value: request.projectManagerResponse, disabled: this.isDisabled }],
              inspectorName: [{ value: request.inspectorId, disabled: this.isDisabled }],
              inspectorResponse: [{ value: request.inspectorResponse, disabled: this.isDisabled }],
              projectManagerResponseDate: [this.datePipe.transform(request.projectManagerResponseDate, "yyyy-MM-dd HH:mm")],
              inspectorResponseDate: [this.datePipe.transform(request.inspectorResponseDate, "yyyy-MM-dd HH:mm")],

              projectManagerNote: [{ value: request.projectManagerNote, disabled: this.isDisabled }],
              inspectorNote: [{ value: request.inspectorNote, disabled: this.isDisabled }],
              requestFrom: [request.requestFrom],
              requestTo: [request.requestTo],
            }));
          });
        }


        //Admin
        if (this.usertype == '5') {
          (response as RequestModel[]).forEach((request: RequestModel) => {
            this.requestForms.push(this.fb.group({
              id: [request.id],
              title: [request.title],
              pointName: [request.pointName],
              projectName: [request.projectName],
              engineeringType: [{ value: request.engineeringType, disabled: this.isDisabled }],
              submitLevel: [request.submitLevel],
              requestNumber: [request.requestNumber],
              requestName: [request.requestName, Validators.required],
              contractorName: [request.contractorName, Validators.required],
              submitDate: [this.datePipe.transform(request.submitDate, "yyyy-MM-dd HH:mm")],
              dueDate: [this.datePipe.transform(request.dueDate, "yyyy-MM-dd HH:mm")],
              projectManagerName: [{ value: request.projectManagerId, disabled: this.isDisabled }],
              projectManagerResponse: [{ value: request.projectManagerResponse, disabled: this.isDisabled }],
              inspectorName: [{ value: request.inspectorId, disabled: this.isDisabled }],
              inspectorResponse: [{ value: request.inspectorResponse, disabled: this.isDisabled }],
              projectManagerResponseDate: [this.datePipe.transform(request.projectManagerResponseDate, "yyyy-MM-dd HH:mm")],
              inspectorResponseDate: [this.datePipe.transform(request.inspectorResponseDate, "yyyy-MM-dd HH:mm")],


              projectManagerNote: [{ value: request.projectManagerNote, disabled: this.isDisabled }],
              inspectorNote: [{ value: request.inspectorNote, disabled: this.isDisabled }],
              requestFrom: [request.requestFrom],
              requestTo: [request.requestTo],
            }));
          });
        }

        this.isLoading = false;

      }, error => {
        console.log(error);
      });
    }
    else {
      this.requestsGet(false);
    }

  }

  public requestsSearch = (search: string) => {
    let userId = localStorage.getItem('userId');
    console.log(this.dateFrom);
    if (search == null) {
      search = '';
    }
    if (this.selectedRequestType == null) {
      this.selectedRequestType = '0';
    }
    if (this.selectedProject == null) {
      this.selectedProject = '0';
    }
    if (this.dateSearchFrom == null) {
      this.dateSearchFrom = this.datePipe.transform(new Date(), "yyyy-MM-dd")
    }
    if (this.dateSearchTo == null) {
      this.dateSearchTo = this.datePipe.transform(new Date(), "yyyy-MM-dd")
    }
    this.isLoading = true;
    this.repo.getData('api/requests/requestsSearch?query=' + search + '&requestTypeId='
      + this.selectedRequestType + '&dateFrom=' + this.dateSearchFrom + '&dateTo='
      + this.dateSearchTo + '&userId=' + userId + '&userType=' + this.usertype + '&projectId=' + this.selectedProject)
      .subscribe(response => {

        this.requestForms.clear();
        //Project manager
        if (this.usertype == '0') {
          (response as RequestModel[]).forEach((request: RequestModel) => {
            this.requestForms.push(this.fb.group({
              id: [request.id],
              title: [request.title],
              pointName: [request.pointName],
              projectName: [request.projectName],
              engineeringType: [{ value: request.engineeringType, disabled: this.isDisabled }],
              submitLevel: [request.submitLevel],
              requestNumber: [request.requestNumber],
              requestName: [request.requestName, Validators.required],
              contractorName: [request.contractorName, Validators.required],
              submitDate: [this.datePipe.transform(request.submitDate, "yyyy-MM-dd HH:mm")],
              dueDate: [this.datePipe.transform(request.dueDate, "yyyy-MM-dd HH:mm")],
              projectManagerName: [{ value: request.projectManagerId, disabled: this.isDisabled }],
              projectManagerResponse: [request.projectManagerResponse],
              inspectorName: [{ value: request.inspectorId, disabled: this.isDisabled }],
              inspectorResponse: [{ value: request.inspectorResponse, disabled: this.isDisabled }],
              projectManagerResponseDate: [this.datePipe.transform(request.projectManagerResponseDate, "yyyy-MM-dd HH:mm")],
              inspectorResponseDate: [this.datePipe.transform(request.inspectorResponseDate, "yyyy-MM-dd HH:mm")],

              projectManagerNote: [request.projectManagerNote],
              inspectorNote: [{ value: request.inspectorNote, disabled: this.isDisabled }],
              requestFrom: [request.requestFrom],
              requestTo: [request.requestTo],
            }));
          });
        }
        //Sub admin
        if (this.usertype == '1') {
          (response as RequestModel[]).forEach((request: RequestModel) => {
            this.requestForms.push(this.fb.group({
              id: [request.id],
              title: [request.title],
              pointName: [request.pointName],
              projectName: [request.projectName],
              engineeringType: [{ value: request.engineeringType, disabled: this.isDisabled }],
              submitLevel: [request.submitLevel],
              requestNumber: [request.requestNumber],
              requestName: [request.requestName, Validators.required],
              contractorName: [request.contractorName, Validators.required],
              submitDate: [this.datePipe.transform(request.submitDate, "yyyy-MM-dd HH:mm")],
              dueDate: [this.datePipe.transform(request.dueDate, "yyyy-MM-dd HH:mm")],
              projectManagerName: [request.projectManagerId],
              projectManagerResponse: [{ value: request.projectManagerResponse, disabled: this.isDisabled }],
              inspectorName: [request.inspectorId],
              inspectorResponse: [{ value: request.inspectorResponse, disabled: this.isDisabled }],
              projectManagerResponseDate: [this.datePipe.transform(request.projectManagerResponseDate, "yyyy-MM-dd HH:mm")],
              inspectorResponseDate: [this.datePipe.transform(request.inspectorResponseDate, "yyyy-MM-dd HH:mm")],

              projectManagerNote: [{ value: request.projectManagerNote, disabled: this.isDisabled }],
              inspectorNote: [{ value: request.inspectorNote, disabled: this.isDisabled }],
              requestFrom: [request.requestFrom],
              requestTo: [request.requestTo],
            }));
          });
        }
        //Inspector
        if (this.usertype == '2') {
          (response as RequestModel[]).forEach((request: RequestModel) => {
            this.requestForms.push(this.fb.group({
              id: [request.id],
              title: [request.title],
              pointName: [request.pointName],
              projectName: [request.projectName],
              engineeringType: [{ value: request.engineeringType, disabled: this.isDisabled }],
              submitLevel: [request.submitLevel],
              requestNumber: [request.requestNumber],
              requestName: [request.requestName, Validators.required],
              contractorName: [request.contractorName, Validators.required],
              submitDate: [this.datePipe.transform(request.submitDate, "yyyy-MM-dd HH:mm")],
              dueDate: [this.datePipe.transform(request.dueDate, "yyyy-MM-dd HH:mm")],
              projectManagerName: [{ value: request.projectManagerId, disabled: this.isDisabled }],
              projectManagerResponse: [{ value: request.projectManagerResponse, disabled: this.isDisabled }],
              inspectorName: [{ value: request.inspectorId, disabled: this.isDisabled }],
              inspectorResponse: [request.inspectorResponse],
              projectManagerResponseDate: [this.datePipe.transform(request.projectManagerResponseDate, "yyyy-MM-dd HH:mm")],
              inspectorResponseDate: [this.datePipe.transform(request.inspectorResponseDate, "yyyy-MM-dd HH:mm")],

              projectManagerNote: [{ value: request.projectManagerNote, disabled: this.isDisabled }],
              inspectorNote: [request.inspectorNote],
              requestFrom: [request.requestFrom],
              requestTo: [request.requestTo],
            }));
          });
        }
        //contractir
        if (this.usertype == '3') {
          (response as RequestModel[]).forEach((request: RequestModel) => {
            this.requestForms.push(this.fb.group({
              id: [request.id],
              title: [request.title],
              pointName: [request.pointName],
              projectName: [request.projectName],
              engineeringType: [{ value: request.engineeringType, disabled: this.isDisabled }],
              submitLevel: [request.submitLevel],
              requestNumber: [request.requestNumber],
              requestName: [request.requestName, Validators.required],
              contractorName: [request.contractorName, Validators.required],
              submitDate: [this.datePipe.transform(request.submitDate, "yyyy-MM-dd HH:mm")],
              dueDate: [this.datePipe.transform(request.dueDate, "yyyy-MM-dd HH:mm")],
              projectManagerName: [{ value: request.projectManagerId, disabled: this.isDisabled }],
              projectManagerResponse: [{ value: request.projectManagerResponse, disabled: this.isDisabled }],
              inspectorName: [{ value: request.inspectorId, disabled: this.isDisabled }],
              inspectorResponse: [{ value: request.inspectorResponse, disabled: this.isDisabled }],
              projectManagerResponseDate: [this.datePipe.transform(request.projectManagerResponseDate, "yyyy-MM-dd HH:mm")],
              inspectorResponseDate: [this.datePipe.transform(request.inspectorResponseDate, "yyyy-MM-dd HH:mm")],

              projectManagerNote: [{ value: request.projectManagerNote, disabled: this.isDisabled }],
              inspectorNote: [{ value: request.inspectorNote, disabled: this.isDisabled }],
              requestFrom: [request.requestFrom],
              requestTo: [request.requestTo],
            }));
          });
        }
        //viewer
        if (this.usertype == '4') {
          (response as RequestModel[]).forEach((request: RequestModel) => {
            this.requestForms.push(this.fb.group({
              id: [request.id],
              title: [request.title],
              pointName: [request.pointName],
              projectName: [request.projectName],
              engineeringType: [{ value: request.engineeringType, disabled: this.isDisabled }],
              submitLevel: [request.submitLevel],
              requestNumber: [request.requestNumber],
              requestName: [request.requestName, Validators.required],
              contractorName: [request.contractorName, Validators.required],
              submitDate: [this.datePipe.transform(request.submitDate, "yyyy-MM-dd HH:mm")],
              dueDate: [this.datePipe.transform(request.dueDate, "yyyy-MM-dd HH:mm")],
              projectManagerName: [{ value: request.projectManagerId, disabled: this.isDisabled }],
              projectManagerResponse: [{ value: request.projectManagerResponse, disabled: this.isDisabled }],
              inspectorName: [{ value: request.inspectorId, disabled: this.isDisabled }],
              inspectorResponse: [{ value: request.inspectorResponse, disabled: this.isDisabled }],
              projectManagerResponseDate: [this.datePipe.transform(request.projectManagerResponseDate, "yyyy-MM-dd HH:mm")],
              inspectorResponseDate: [this.datePipe.transform(request.inspectorResponseDate, "yyyy-MM-dd HH:mm")],

              projectManagerNote: [{ value: request.projectManagerNote, disabled: this.isDisabled }],
              inspectorNote: [{ value: request.inspectorNote, disabled: this.isDisabled }],
              requestFrom: [request.requestFrom],
              requestTo: [request.requestTo],
            }));
          });
        }
        //Admin
        if (this.usertype == '5') {
          (response as RequestModel[]).forEach((request: RequestModel) => {
            this.requestForms.push(this.fb.group({
              id: [request.id],
              title: [request.title],
              pointName: [request.pointName],
              projectName: [request.projectName],
              engineeringType: [{ value: request.engineeringType, disabled: this.isDisabled }],
              submitLevel: [request.submitLevel],
              requestNumber: [request.requestNumber],
              requestName: [request.requestName, Validators.required],
              contractorName: [request.contractorName, Validators.required],
              submitDate: [this.datePipe.transform(request.submitDate, "yyyy-MM-dd HH:mm")],
              dueDate: [this.datePipe.transform(request.dueDate, "yyyy-MM-dd HH:mm")],
              projectManagerName: [{ value: request.projectManagerId, disabled: this.isDisabled }],
              projectManagerResponse: [{ value: request.projectManagerResponse, disabled: this.isDisabled }],
              inspectorName: [{ value: request.inspectorId, disabled: this.isDisabled }],
              inspectorResponse: [{ value: request.inspectorResponse, disabled: this.isDisabled }],
              projectManagerResponseDate: [this.datePipe.transform(request.projectManagerResponseDate, "yyyy-MM-dd HH:mm")],
              inspectorResponseDate: [this.datePipe.transform(request.inspectorResponseDate, "yyyy-MM-dd HH:mm")],


              projectManagerNote: [{ value: request.projectManagerNote, disabled: this.isDisabled }],
              inspectorNote: [{ value: request.inspectorNote, disabled: this.isDisabled }],
              requestFrom: [request.requestFrom],
              requestTo: [request.requestTo],
            }));
          });
        }
        console.log(response);
        this.isLoading = false;
      }, (error) => {
        console.log(error);
      })

  }

  onKeyUp(event: any) {
    console.log(event.target.value);
    if (event.target.value == '') {
      this.requestsGet(false);
    }
  }



  changeType(event: any) {
    console.log(event);
    this.selectedRequestType = event;
  }


  changeProject(event: any) {
    console.log(event);
    this.selectedProject = event;
    this.requestTypeGetDropDownListByProjectId(event);
  }

  changeFromDate(event: any) {
    this.dateSearchFrom = this.datePipe.transform(event, "yyyy-MM-dd");
    console.log(this.dateSearchFrom);
  }
  changeToDate(event: any) {
    this.dateSearchTo = this.datePipe.transform(event, "yyyy-MM-dd");
  }

  public onDeleteClick = (id: string) => {

    const dialogRef = this.confirmDialogService.openConfirmDialog('هل انت متأكد من رغبتك في حذف الريكوست');
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.repo.delete('api/requests/requestDelete?id=' + id).subscribe(response => {
          this.requestsGet(false);
          this.notificationService.delete('تم حذف الريكوست بنجاح');
        }, error => {
          console.log(error)
        })

      }
    });

  }



  //Helpers ===========================================
  applySortingAndPaging(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public search = (value: string) => {
    //this.dataSource.filter = value.trim().toLocaleLowerCase();
    console.log(value)
    this.unResponsedchecked = false;
    this.requestsSearch(value)
  }

  public clearSearch = (value: string) => {
    this.requestsGet(true);

  }

}
