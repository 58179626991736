import { StationsForMaps } from './../../_models/StationsForMaps';
import { MapData } from './../../_models/MapData';
import { PointsForMaps } from './../../_models/PointsForMaps';
import { LatLng } from './../../_models/LatLng';
import { MapCoordinates } from './../../_models/MapCoordinates';
import { Component, OnInit } from '@angular/core';
import { Loader } from "@googlemaps/js-api-loader"
import { google, load } from "google-maps";
import { RepositoryService } from 'src/app/services/repository.service';
import { ProjectDropDownList } from 'src/app/_models/ProjectDropDownList';
import { DatePipe } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {

  reportForm: FormGroup;

  isLoading: boolean;
  projectsDropDownList: ProjectDropDownList[] = [];
  mapData: MapData;

  dateFrom: string;

  dateSearchFrom: string;

  apiKey: string = "AIzaSyBCut2A4NX5iMcsu7U6iW5FmvYDLnl_4h4"

  map: google.maps.Map;

  mapCoordinates: MapCoordinates[] = [];

  pointsCoordinates: PointsForMaps[] = [];
  stationsCoordinates: StationsForMaps[] = [];

  lineIdExpermintal: string = '11';

  color: string = '#000000';

  constructor(public datePipe: DatePipe, private repo: RepositoryService, public notificationsService: NotificationsService) { }

  ngOnInit(): void {
    //this.getCoordinates();
    // alert("Exception Drawing Polygon - Error number P1053")
    this.dateFrom = this.currentDate();
    this.dateSearchFrom = this.dateFrom;
    let userType = localStorage.getItem('userType');
    if (userType == '0') {
      this.projectsGetDropDownListByUserId()
    }
    else {
      this.projectsGetDropDownList();

    }

  }

  public createRequestsForm() {
    this.reportForm = new FormGroup({
      projectId: new FormControl(this.projectsDropDownList[0].id),
      without: new FormControl(false),
    });
  }











  public onSubmit = (formValues) => {
    console.log(formValues.projectId);
    this.initMap(formValues.projectId, formValues.without);
    this.showData(formValues.projectId);
  }



  private initMap(projectId: string, without: boolean) {
    console.log('without', without)
    console.log(this.dateSearchFrom)
    const loader = new Loader({
      apiKey: this.apiKey,
      version: "weekly",
    });
    this.isLoading = true;
    this.repo.getData('api/LineCoordinate/MapCoordinatesGet?projectId=' + projectId + '&creationDate=' + this.dateSearchFrom + '&without=' + without)
      .subscribe(response => {

        this.mapCoordinates = response as MapCoordinates[]

        console.log('Coordinates are :-  ', this.mapCoordinates);
        this.isLoading = false;
        if (this.mapCoordinates.length > 0) {
          const mapCenter = {
            lat: this.mapCoordinates[Math.floor(this.mapCoordinates.length / 2)].firstPointLatitude,
            lng: this.mapCoordinates[Math.floor(this.mapCoordinates.length / 2)].firstPointLongitude
          };
          loader.load().then(() => {

            this.map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
              center: mapCenter,
              zoom: 17,
            });

          }).then(() => {
            this.showStations(projectId, without);
          }).then(() => {
            this.showPoints(projectId);
          }).then(() => {
            this.showLines();
          });

        }
        else {
          this.notificationsService.delete('لا توجد احداثيات لهذا المشروع!');
        }




      }, (error) => {
        console.log(error);
      })

  }

  public showLines() {
    console.log('number of coordinates', this.mapCoordinates.length);
    this.mapCoordinates.forEach(element => {
      console.log('Point is',element);
      let points = [
        { lat: element.firstPointLatitude, lng: element.firstPointLongitude }
        , { lat: element.secondPointLatitude, lng: element.secondPointLongitude }
      ]
      
      const roadPoints = points as any
      const roadLine = new google.maps.Polyline({
        path: roadPoints,
        geodesic: true,
        strokeColor: element.color,
        strokeOpacity: 1.0,
        strokeWeight: 5,
      });
      roadLine.setMap(this.map);
    })
  }

  //MAIN METHOD
  public showStations(projectId: string, without: boolean) {
    console.log('without=', without);
    this.repo.getData('api/LineCoordinate/StationsGet?projectId=' + projectId + '&without=' + without)
      .subscribe(response => {
        this.stationsCoordinates = response as StationsForMaps[];
        console.log('Stations are :-  ', response);
        if (!without) {
          for (let i = 0; i < this.stationsCoordinates.length; i++) {
            let station = this.stationsCoordinates[i];

            var marker = new google.maps.Marker({
              position: { lat: station.lat, lng: station.lng },
              title: '(' + station.entryName + ')' + '/' + station.name,
              label: '(' + station.entryName + ')' + '/' + station.name,
              icon: "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png"
            });
            marker.setMap(this.map);
          }
        }
        else {
          for (let i = 0; i < this.stationsCoordinates.length; i++) {
            let station = this.stationsCoordinates[i];

            var marker = new google.maps.Marker({
              position: { lat: station.lat, lng: station.lng },
              title: '(' + station.roomTypeName + ')' + '/' + station.name,
              label: '(' + station.roomTypeName + ')' + '/' + station.name,
              icon: "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png"
            });
            marker.setMap(this.map);
          }
        }


      });
  }

  public showPoints(projectId: string) {
    this.repo.getData('api/LineCoordinate/PointsCoordinatesGet?projectId=' + projectId)
      .subscribe(response => {
        this.pointsCoordinates = response as PointsForMaps[];
        console.log('Points are :-  ', response);
        for (let i = 0; i < this.pointsCoordinates.length; i++) {
          let point = this.pointsCoordinates[i];

          var marker = new google.maps.Marker({
            position: { lat: point.lat, lng: point.lng },
            //title: "Hello World!",
            label: point.name
          });
          marker.setMap(this.map);
        }

      });
  }

  public showData(projectId: string) {
    this.repo.getData('api/LineCoordinate/GetMapData?projectId=' + projectId + '&creationDate=' + this.dateSearchFrom)
      .subscribe(response => {
        this.mapData = response as MapData;

      });
  }



  public projectsGetDropDownList = () => {
    this.repo.getData('api/projects/projectsGetDropDownList')
      .subscribe(response => {
        this.projectsDropDownList = response as ProjectDropDownList[];
        this.createRequestsForm();
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }


  public projectsGetDropDownListByUserId = () => {
    this.repo.getData('api/projects/ProjectsGetDropDownListByUserId?userId=' + localStorage.getItem('userId') + '&userType=' + localStorage.getItem('userType'))
      .subscribe(response => {
        this.projectsDropDownList = response as ProjectDropDownList[];
        this.createRequestsForm();
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }

  currentDate() {
    const currentDate = new Date();
    return currentDate.toISOString().split('T')[0];
  }

  changeFromDate(event: any) {
    this.dateSearchFrom = this.datePipe.transform(event, "yyyy-MM-dd");
    console.log(this.dateSearchFrom);
  }

}
