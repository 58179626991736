<div class="gradient-Header">
    <div class="gradient-child">
        <h3>المرفقات</h3>
    </div>
</div>


<button style="float: left;margin: 5px;" [routerLink]="['/requests']" mat-raised-button color="secondary">
    <mat-icon>arrow_left</mat-icon>
    عودة
</button>


<div class="mat-elevation-z5">
    <table mat-table [dataSource]="dataSource" matSort>
        <!-- Columns -->
        <ng-container matColumnDef="url">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> الاسم </th>
            <td mat-cell *matCellDef="let element"> {{element.url}} </td>
        </ng-container>

        <!-- Buttons -->
        <ng-container matColumnDef="download">
            <th mat-header-cell *matHeaderCellDef>تحميل</th>
            <td mat-cell *matCellDef="let element">
                <a mat-icon-button color="accent"
                    href="{{baseUrl+'/RequestAttachments/'}}{{element.id}}{{element.extension}}" download="fileName">
                    <mat-icon class="mat-18">download</mat-icon>
                </a>
            </td>
        </ng-container>

        <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef>حذف</th>
            <td mat-cell *matCellDef="let element">
                <button [disabled]="usertype=='0' || usertype=='1'||usertype=='2'||usertype=='4'||usertype=='5'" mat-icon-button
                    color="warn" (click)="onDeleteClick(element.id)">
                    <mat-icon class="mat-18">delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns ; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <!-- Paginator -->
    <mat-paginator [length]="100" [pageSize]="5" showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator>

    <!-- Loading Indicator -->
    <div *ngIf="isLoading"
        style="display: flex; justify-content: center; align-items: center; background: rgb(37, 104, 180);">
        <mat-progress-bar color="secondary" mode="indeterminate">
        </mat-progress-bar>
    </div>
</div>