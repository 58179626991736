import { MatTableDataSource } from '@angular/material/table';
import { NotificationsService } from './../../services/notifications.service';
import { DialogService } from './../../services/dialog.service';
import { RepositoryService } from './../../services/repository.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Component, OnInit, ViewChild } from '@angular/core';
import { RoomType } from '../../_models/RoomType';

@Component({
  selector: 'app-room-types-list',
  templateUrl: './room-types-list.component.html',
  styleUrls: ['./room-types-list.component.css']
})
export class RoomTypesListComponent implements OnInit {

  //Vars ==================================================
  isLoading = true;
  searchText: string;
  query: string;
  lineId: string;
  unitId:string;

  //Table ==================================================
  public displayedColumns = ['name','price', 'lineName', 'update', 'delete','showStations'];
  public dataSource = new MatTableDataSource<RoomType>();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private router: Router,
    private repo: RepositoryService,
    private activeRoute: ActivatedRoute,
    private confirmDialogService: DialogService,
    public notificationsService: NotificationsService) { }

  ngOnInit(): void {
    this.lineId = this.activeRoute.snapshot.params['id'];
    this.unitId = localStorage.getItem('unitId');
    this.roomTypesGet();
  }

  public roomTypesGet = () => {
    this.isLoading = true;
    this.repo.getData('api/roomTypes/roomTypesGet?lineId=' + this.lineId)
      .subscribe(response => {
        this.dataSource.data = response as RoomType[];
        console.log(response);
        this.isLoading = false;
      }, (error) => {
        console.log(error);
      })
  }

  public roomTypesSearch = (name: string) => {
    this.isLoading = true;
    if (name != null) {
      this.repo.getData('api/roomTypes/roomTypesSearch?name=' + name + '&lineId=' + this.lineId)
        .subscribe(response => {
          this.dataSource.data = response as RoomType[];
          console.log(response);
          this.isLoading = false;
        }, (error) => {
          console.log(error);
        })
    }
  }


  public onDeleteClick = (id: string) => {
    const dialogRef = this.confirmDialogService.openConfirmDialog('هل انت متأكد من رغبتك في حذف نوع الغرفة؟');
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.repo.delete('api/roomTypes/roomTypeDelete?id=' + id).subscribe(response => {
          this.roomTypesGet();
          this.notificationsService.delete('تم حذف نوع الغرفة بنجاح');
        }, error => {
          console.log(error)
        })

      }
    });

  }


  //Helpers ===========================================
  applySortingAndPaging(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  onKeyUp(event: any) {
    console.log(event.target.value);
    if (event.target.value == '') {

      this.roomTypesGet();
    }
  }

  public search = (value: string) => {
    //this.dataSource.filter = value.trim().toLocaleLowerCase();
    console.log(value)
    this.roomTypesSearch(value)
  }

}
