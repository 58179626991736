import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MaterialModule } from './material/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProjectsListComponent } from './projects/projects-list/projects-list.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './navigation/header/header.component';
import { SidenavListComponent } from './navigation/sidenav-list/sidenav-list.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NotFoundComponent } from './error-pages/not-found/not-found.component';
import { ProjectsFormComponent } from './projects/projects-form/projects-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MatConfirmDialogComponent } from './mat-confirm-dialog/mat-confirm-dialog.component';
import { UnitsListComponent } from './units/units-list/units-list.component';
import { UnitsFormComponent } from "./units/units-form/units-form.component";
import { RequestTypesListComponent } from './requestTypes/request-types-list/request-types-list.component';
import { RequestTypesFormComponent } from './requestTypes/request-types-form/request-types-form.component';
import { UsersListComponent } from './users/users-list/users-list.component';
import { UsersFormComponent } from './users/users-form/users-form.component';
import { RequestsFormComponent } from './requests/requests-form/requests-form.component';
import { FileUploadModule } from 'ng2-file-upload';
import { LoginComponent } from './login/login.component';
import { RequestsListComponent } from './requests/requests-list/requests-list.component';
import { RequestAttachmentsComponent } from './request-attachments/request-attachments.component';
import { PointsListComponent } from './points/points-list/points-list.component';
import { PointsFormComponent } from './points/points-form/points-form.component';
import { PointsBuildingComponent } from './pointsbuilding/points-building/points-building.component';
import { PointsBuildingFormComponent } from './pointsbuilding/points-building-form/points-building-form.component';
import { PointsDivisionsComponent } from './divisions/points-divisions/points-divisions.component';
import { PointsDivisionsFormComponent } from './divisions/points-divisions-form/points-divisions-form.component';
import { PointsTermsComponent } from './terms/points-terms/points-terms.component';
import { PointsTermsFormComponent } from './terms/points-terms-form/points-terms-form.component';
import { PointTermsEntriesFormComponent } from './terms/point-terms-entries-form/point-terms-entries-form.component';
import { PointTermsListComponent } from './terms/point-terms-list/point-terms-list.component';
import { UploadPdfFileDialogComponent } from './upload-pdf-file-dialog/upload-pdf-file-dialog.component';
import { ChartRequestListComponent } from './_charts/chart-request-list/chart-request-list.component';
import { TimeFrameListComponent } from './timeFrame/time-frame-list/time-frame-list.component';
import { TimeFrameFormComponent } from './timeFrame/time-frame-form/time-frame-form.component';
import { LinesListComponent } from './lines/lines-list/lines-list.component';
import { LinesFormComponent } from './lines/lines-form/lines-form.component';
import { LinesDivisionsListComponent } from './lines-divisions/lines-divisions-list/lines-divisions-list.component';
import { LinesDivisionsFormComponent } from './lines-divisions/lines-divisions-form/lines-divisions-form.component';
import { LinesCoordinatesListComponent } from './lines-coordinates/lines-coordinates-list/lines-coordinates-list.component';
import { LinesCoordinatesFormComponent } from './lines-coordinates/lines-coordinates-form/lines-coordinates-form.component';
import { RoomTypesListComponent } from './room-types/room-types-list/room-types-list.component';
import { RoomTypesFormComponent } from './room-types/room-types-form/room-types-form.component';
import { PipesDivisionsListComponent } from './pipesDivisions/pipes-divisions-list/pipes-divisions-list.component';
import { PipesDivisionsFormComponent } from './pipesDivisions/pipes-divisions-form/pipes-divisions-form.component';
import { PipesTermsListComponent } from './pipesTerms/pipes-terms-list/pipes-terms-list.component';
import { PipesTermsFormComponent } from './pipesTerms/pipes-terms-form/pipes-terms-form.component';
import { PipeTermsEntriesListComponent } from './terms/pipe-terms-entries-list/pipe-terms-entries-list.component';
import { PipeTermsEntriesFormComponent } from './terms/pipe-terms-entries-form/pipe-terms-entries-form.component';
import { RoomsDivisionsListComponent } from './roomDivisions/rooms-divisions-list/rooms-divisions-list.component';
import { RoomsDivisionsFormComponent } from './roomDivisions/rooms-divisions-form/rooms-divisions-form.component';
import { RoomsTermsFormComponent } from './roomsTerms/rooms-terms-form/rooms-terms-form.component';
import { RoomsTermsListComponent } from './roomsTerms/rooms-terms-list/rooms-terms-list.component';
import { RoomsStationsListComponent } from './roomStations/rooms-stations-list/rooms-stations-list.component';
import { RoomsStationsFormComponent } from './roomStations/rooms-stations-form/rooms-stations-form.component';
import { RoomsEntriesFormComponent } from './roomEntries/rooms-entries-form/rooms-entries-form.component';
import { RoomsEntriesListComponent } from './roomEntries/rooms-entries-list/rooms-entries-list.component';
import { PipeTermsTypesListComponent } from './pipeTermsTypes/pipe-terms-types-list/pipe-terms-types-list.component';
import { PipeTermsTypesFormComponent } from './pipeTermsTypes/pipe-terms-types-form/pipe-terms-types-form.component';
import { WeeklyReportFormComponent } from './reports-weekly/weekly-report-form/weekly-report-form.component';
import { MapComponent } from './_map/map/map.component';
import { HomeComponent } from './_home/home/home.component';
import { GDashboardLinksComponent } from './gdashboard-links/gdashboard-links.component';
import { ReportViewerComponent } from './report-viewer/report-viewer.component';

@NgModule({
  declarations: [
    AppComponent,
    ProjectsListComponent,
    LayoutComponent,
    HeaderComponent,
    SidenavListComponent,
    NotFoundComponent,
    ProjectsFormComponent,
    MatConfirmDialogComponent,
    UnitsListComponent,
    UnitsFormComponent,
    RequestTypesListComponent,
    RequestTypesFormComponent,
    UsersListComponent,
    UsersFormComponent,
    RequestsFormComponent,
    LoginComponent,
    RequestsListComponent,
    RequestAttachmentsComponent,
    PointsListComponent,
    PointsFormComponent,
    PointsBuildingComponent,
    PointsBuildingFormComponent,
    PointsDivisionsComponent,
    PointsDivisionsFormComponent,
    PointsTermsComponent,
    PointsTermsFormComponent,
    PointTermsEntriesFormComponent,
    PointTermsListComponent,
    UploadPdfFileDialogComponent,
    ChartRequestListComponent,
    TimeFrameListComponent,
    TimeFrameFormComponent,
    LinesListComponent,
    LinesFormComponent,
    LinesDivisionsListComponent,
    LinesDivisionsFormComponent,
    LinesCoordinatesListComponent,
    LinesCoordinatesFormComponent,
    RoomTypesListComponent,
    RoomTypesFormComponent,
    PipesDivisionsListComponent,
    PipesDivisionsFormComponent,
    PipesTermsListComponent,
    PipesTermsFormComponent,
    PipeTermsEntriesListComponent,
    PipeTermsEntriesFormComponent,
    RoomsDivisionsListComponent,
    RoomsDivisionsFormComponent,
    RoomsTermsFormComponent,
    RoomsTermsListComponent,
    RoomsStationsListComponent,
    RoomsStationsFormComponent,
    RoomsEntriesFormComponent,
    RoomsEntriesListComponent,
    PipeTermsTypesListComponent,
    PipeTermsTypesFormComponent,
    WeeklyReportFormComponent,
    MapComponent,
    HomeComponent,
    GDashboardLinksComponent,
    ReportViewerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    NgbModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FileUploadModule,
    
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
