import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as moment from 'moment';
import { RepositoryService } from 'src/app/services/repository.service';
import { ProjectDropDownList } from 'src/app/_models/ProjectDropDownList';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  urlSafe: SafeResourceUrl;
  url: string;
  dateFrom: string;
  dateSearchFrom: string;
  reportForm: FormGroup;
  projectsDropDownList: ProjectDropDownList[] = [];
  constructor(public datePipe: DatePipe, private repo: RepositoryService, private http: HttpClient, public sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.dateFrom = this.currentDate()
    this.dateSearchFrom = this.dateFrom;
    this.projectsGetDropDownList();
  }

  public createRequestsForm() {
    this.reportForm = new FormGroup({
      projectId: new FormControl(this.projectsDropDownList[0].id),
    });
  }

  public projectsGetDropDownList = () => {
    this.repo.getData('api/projects/projectsGetDropDownList')
      .subscribe(response => {
        this.projectsDropDownList = response as ProjectDropDownList[];
        this.createRequestsForm()

        let from = moment(this.dateSearchFrom).format('YYYY-MM-DD');
        let to = moment(this.dateSearchFrom).add(7, 'days').format('YYYY-MM-DD');


        this.url = `http://adlink2019-001-site37.etempurl.com/indexdaily.aspx?
         userId=${this.projectsDropDownList[0].id}&fromDate=${from}&toDate=${to}`

        // window.location.href = this.url.split(' ').join('');

        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url.split(' ').join(''));

        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }

  onSubmit(formValues) {
    console.log(formValues.projectId)

    let from = moment(this.dateSearchFrom).format('YYYY-MM-DD');
    let to = moment(this.dateSearchFrom).add(7, 'days').format('YYYY-MM-DD');


    this.url = `http://adlink2019-001-site37.etempurl.com/indexdaily.aspx?
    userId=${formValues.projectId}&fromDate=${from}&toDate=${to}`

    // window.location.href = this.url.split(' ').join('');

    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url.split(' ').join(''));
  }

  changeFromDate(event: any) {
    this.dateSearchFrom = this.datePipe.transform(event, "yyyy-MM-dd");
    console.log(this.dateSearchFrom);
  }

  currentDate() {
    const currentDate = new Date();
    return currentDate.toISOString().split('T')[0];
  }
}
