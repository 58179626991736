import { PipeTermsTypeDropDownList } from './../../_models/PipeTermsTypeDropDownList';
import { PipeTermDropDownList } from './../../_models/PipeTermDropDownList';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from 'src/app/services/notifications.service';
import { RepositoryService } from 'src/app/services/repository.service';
import { PipeTerm } from 'src/app/_models/PipeTerm';

@Component({
  selector: 'app-pipes-terms-form',
  templateUrl: './pipes-terms-form.component.html',
  styleUrls: ['./pipes-terms-form.component.css']
})
export class PipesTermsFormComponent implements OnInit {

  //Vars ====================================
  pipesTermsForm: FormGroup;
  pipesTerm: PipeTerm;
  pipeDivisionId: string;
  id: string;
  isUpdating: boolean = false;

  pipeTermsTypesDropDownList: PipeTermsTypeDropDownList[] = [];

  constructor(private router: Router,
    private repo: RepositoryService,
    private activeRoute: ActivatedRoute,
    public notificationsService: NotificationsService) { }

  ngOnInit(): void {
    this.pipeDivisionId = this.activeRoute.snapshot.params['pipeDivisionId'];
    this.id = this.activeRoute.snapshot.params['id'];
    this.pipesTermsTypesGetDropDownList();

    console.log(this.id);
 
    if (this.id != null) {
      this.isUpdating = true;
      this.pipesTermsGetById(this.id);
    }
  }


  public createpipesTermssForm() {
    this.pipesTermsForm = new FormGroup({
      id: new FormControl(null),
      name: new FormControl('', [Validators.required, Validators.maxLength(200)]),
      price: new FormControl(0, [Validators.required, Validators.maxLength(200)]),
      maxQuantity: new FormControl(0, [Validators.required, Validators.maxLength(200)]),
      unit: new FormControl('', [Validators.required, Validators.maxLength(200)]),
      calculationConstant: new FormControl(0, [Validators.required, Validators.maxLength(200)]),
      priority: new FormControl(0, [Validators.required, Validators.maxLength(200)]),
      pipeTermTypeId: new FormControl(0,[Validators.required, Validators.min(0)])
    });
  }

  public onSubmit = (pipesTermsFormValue) => {
    if (this.pipesTermsForm.valid) {
      if (this.isUpdating) {
        this.pipesTermsUpdate(pipesTermsFormValue);
      }
      else {
        this.pipesTermsCreate(pipesTermsFormValue);
      }
    }
  }

  private pipesTermsCreate = (pipesTermsFormValue) => {
    let pipesTerms: PipeTerm = {
      name: pipesTermsFormValue.name,
      price: pipesTermsFormValue.price,
      pipeDivisionId: this.pipeDivisionId,
      unit: pipesTermsFormValue.unit,
      maxQuantity: pipesTermsFormValue.maxQuantity,
      calculationConstant: pipesTermsFormValue.calculationConstant,
      priority: pipesTermsFormValue.priority,
      pipeTermTypeId: pipesTermsFormValue.pipeTermTypeId,
    }
    console.log(pipesTerms);
    this.repo.create('api/PipeTerms/PipeTermsCreate', pipesTerms)
      .subscribe(response => {
        this.notificationsService.success('تمت الاضافة بنجاح!');
        this.onCancel();
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }


  public pipesTermsTypesGetDropDownList = () => {
    this.repo.getData('api/PipeTermsTypess/PipeTermsTypessGetDropDownList?pipeDivisionId=' + this.pipeDivisionId)
      .subscribe(response => {
        this.pipeTermsTypesDropDownList = response as PipeTermDropDownList[];
        console.log('pipeTermsDropDownList', this.pipeTermsTypesDropDownList)
        this.createpipesTermssForm();
        console.log(response);

      }, (error) => {
        console.log(error);
      })
  }


  public pipesTermsGetById = (id: string) => {
    this.repo.getData('api/PipeTerms/PipeTermGetById?id=' + id).subscribe(response => {
      this.pipesTerm = response as PipeTerm
      // this.pipesTermsForm.patchValue(this.pipesTerms);
      this.pipesTermsForm.get('id').setValue(this.pipesTerm.id);
      this.pipesTermsForm.get('name').setValue(this.pipesTerm.name);
      console.log(response);
    }, (error) => {
      console.log(error);
    })
  }


  public pipesTermsUpdate = (pipesTermsFormValue) => {
    let pipesTerms: PipeTerm = {
      id: pipesTermsFormValue.id,
      name: pipesTermsFormValue.name,
      pipeDivisionId: this.pipeDivisionId,
      unit: pipesTermsFormValue.unit,
      maxQuantity: pipesTermsFormValue.maxQuantity,
      calculationConstant: pipesTermsFormValue.calculationConstant,
      priority: pipesTermsFormValue.priority,
      pipeTermTypeId: pipesTermsFormValue.pipeTermTypeId,
    }
    console.log(pipesTerms);
    this.repo.update('api/PipeTerms/PipeTermUpdate', pipesTerms)
      .subscribe(response => {
        this.notificationsService.success('تم التعديل بنجاح!');
        this.onCancel();
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }

  //Errors ======================================
  public hasError = (controlName: string, errorName: string) => {
    return this.pipesTermsForm.controls[controlName].hasError(errorName);
  }
  public onCancel = () => {
    this.router.navigate(['/pipes-terms-list/' + this.pipeDivisionId])
  }

}
