import { RoomStationDropDownList } from './../../_models/RoomStationDropDownList';
import { RoomTypeDropDownList } from './../../_models/RoomTypeDropDownList';
import { RoomTermDropDownList } from './../../_models/RoomTermDropDownList';
import { RoomDivisionDropDownList } from './../../_models/RoomDivisionDropDownList';
import { LineDropDownList } from './../../_models/LineDropDownList';
import { RoomTermsEntries } from './../../_models/RoomTermsEntries';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RepositoryService } from 'src/app/services/repository.service';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-rooms-entries-form',
  templateUrl: './rooms-entries-form.component.html',
  styleUrls: ['./rooms-entries-form.component.css']
})
export class RoomsEntriesFormComponent implements OnInit {
  //Vars ====================================
  roomTermsEntiresForm: FormGroup;

  linesList: LineDropDownList[] = [];
  roomDivisionList: RoomDivisionDropDownList[] = [];
  roomTermList: RoomTermDropDownList[] = [];
  roomTypeList: RoomTypeDropDownList[] = [];
  roomStationList: RoomStationDropDownList[] = [];

  maxQuantityFromTerms: number = 0;
  availableQuantity: number = 0;
  termUnit: string;
  formData: FormData;
  selectedFiles: FileList;
  fileToUpload: File;

  baseUrl = environment.urlAddress;
  roomTermEntrie: RoomTermsEntries;
  isUpdating: boolean = false;
  id: string;
  public progress: number;
  entryId: string;
  isLoading: boolean = false;
  inspectorId: string = localStorage.getItem('userId')
  quantityMaxAllowedValue: number = 0;

  constructor(private router: Router,
    private repo: RepositoryService,
    private http: HttpClient,
    private activeRoute: ActivatedRoute,
    public notificationsService: NotificationsService) { }


  ngOnInit(): void {
    this.createPointsTermsEntriesForm();
    this.id = this.activeRoute.snapshot.params['id'];
    this.lineGetDropDownList();
  }

  public createPointsTermsEntriesForm() {
    console.log('pointTermsEntriesForm');
    this.roomTermsEntiresForm = new FormGroup({
      id: new FormControl(null),
      note: new FormControl('', [Validators.maxLength(200)]),
      lineId: new FormControl(0, [Validators.min(1)]),
      roomDivisionId: new FormControl(0, [Validators.required]),
      roomTermId: new FormControl(0, [Validators.required]),
      roomStationId: new FormControl(0, [Validators.required]),
      roomTypeId: new FormControl(0, [Validators.required]),
    });

  }

  linesSelectionChanged(event: any) {
    console.log(event);
    let selectedUnit = event;
    this.roomDivisionDropDownList(event);
    this.roomTypeDropDownList(event);
  }

  roomDivisionSelectionChanged(event: any) {
    console.log(event);
    let selectedUnit = event;
    this.roomTermDropDownList(event);
  }

  roomTypeSelectionChanged(event: any) {
    console.log(event);
    let selectedUnit = event;
    this.roomStationsDropDownList(event);
  }
  ///===================================================================================================
  public lineGetDropDownList = () => {
    this.isLoading = true;
    //TODO:Add inspector Id
    console.log('lineGetDropDownList');
    this.repo.getData('api/roomDropDownList/lineForPipesTermsEntries?inspectorId='+localStorage.getItem('userId')).subscribe(response => {
      this.linesList = response as LineDropDownList[]
      this.isLoading = false;
    }, (error) => {
      console.log(error);
    })
  }



  public roomDivisionDropDownList = (lineId: string) => {
    this.isLoading = true;
    //TODO:Add inspector Id
    console.log('roomDivisionDropDownList');
    this.repo.getData('api/roomDropDownList/division?lineId=' + lineId).subscribe(response => {
      this.roomDivisionList = response as RoomDivisionDropDownList[]
      this.isLoading = false;
    }, (error) => {
      console.log(error);
    })
  }


  public roomTermDropDownList = (roomDivisionId: string) => {
    this.isLoading = true;
    //TODO:Add inspector Id
    console.log('roomTermDropDownList');
    this.repo.getData('api/roomDropDownList/term?roomDivisionId=' + roomDivisionId).subscribe(response => {
      this.roomTermList = response as RoomTermDropDownList[]
      this.isLoading = false;
    }, (error) => {
      console.log(error);
    })
  }


  public roomTypeDropDownList = (lineId: string) => {
    this.isLoading = true;
    //TODO:Add inspector Id
    console.log('roomTypeDropDownList');
    this.repo.getData('api/roomDropDownList/roomType?lineId=' + lineId).subscribe(response => {
      this.roomTypeList = response as RoomTypeDropDownList[]
      this.isLoading = false;
    }, (error) => {
      console.log(error);
    })
  }


  public roomStationsDropDownList = (roomTypeId: string) => {
    this.isLoading = true;
    //TODO:Add inspector Id
    console.log('roomStationsDropDownList');
    this.repo.getData('api/roomDropDownList/roomStation?roomTypeId=' + roomTypeId).subscribe(response => {
      this.roomStationList = response as RoomStationDropDownList[]
      this.isLoading = false;
    }, (error) => {
      console.log(error);
    })
  }

  //Errors ======================================
  public hasError = (controlName: string, errorName: string) => {
    return this.roomTermsEntiresForm.controls[controlName].hasError(errorName);
  }
  public onCancel = () => {
    this.router.navigate(['/rooms-entries-list'])
  }

  public onSubmit = (entryFormValues) => {
    console.log('station', this.roomTermsEntiresForm.getRawValue().roomStationId)
    console.log('term', this.roomTermsEntiresForm.getRawValue().roomTermId)
    if (this.roomTermsEntiresForm.valid) {
      if (this.isUpdating) {
        // this.userUpdate(userFormValue);
      }
      else {
        if (this.fileToUpload == undefined) {
          this.notificationsService.delete('فضلا ادخل صورة الاستلام');
        } else if (this.roomTermsEntiresForm.getRawValue().roomStationId == '0'
          || this.roomTermsEntiresForm.getRawValue().roomTermId == '0') {
          this.notificationsService.delete('فضلا اختر المحطة والبند');
        }
        else {
          this.entryCreate(entryFormValues);
        }
      }
    }
  }

  private entryCreate = (formValues) => {
    this.isLoading = true;
    let term: RoomTermsEntries = {
      inspectorId: this.inspectorId,
      lineId: formValues.lineId,
      roomDivisionId: formValues.roomDivisionId,
      roomTermId: formValues.roomTermId,
      roomStationId: formValues.roomStationId,
      roomTypeId: formValues.roomTypeId,
      note: formValues.note,
    }
    this.repo.create('api/RoomTermEntries/RoomTermEntrieCreate', term)
      .subscribe(response => {
        let obj = response as RoomTermsEntries
        if (obj.note == 'repeated entrie') {
          this.notificationsService.delete('هذا البند مكرر');
          return;
        }
        if (obj.note == 'invalid dependancy') {
          this.notificationsService.delete('فضلا ادخل الاعمال الخاصة بالبند السابق اولا');
          return;
        }
        else {
          this.entryId = obj.id;
          this.uploadFile()
        }

      }, (error) => {
        console.log(error);
      })
  }

  public selectFile = (files) => {
    if (files.length === 0) {
      return;
    }
    this.fileToUpload = <File>files[0];
    this.formData = new FormData();
   // this.formData.append('file', this.fileToUpload, this.fileToUpload.name);
   this.formData.append('file', this.fileToUpload, this.fileToUpload.name.replace('jpeg','jpg').replace('png','jpg'));
  }


  public uploadFile() {
    this.http.post(this.baseUrl + '/api/RoomTermEntries/EntryUploadPhoto?entryId=' + this.entryId
      , this.formData, { reportProgress: true, observe: 'events' })
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response && !this.selectedFiles) {
          console.log('here3')
          this.isLoading = false;
          this.notificationsService.success('تمت الاضافة بنجاح!');
          this.onCancel();
        }
      });
  }
}
