import { PointBuilding } from './../../_models/PointBuilding';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'src/app/services/dialog.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { RepositoryService } from 'src/app/services/repository.service';

@Component({
  selector: 'app-points-building',
  templateUrl: './points-building.component.html',
  styleUrls: ['./points-building.component.css']
})
export class PointsBuildingComponent implements OnInit {
//Vars ==================================================
isLoading = true;
searchText: string;
query: string;
pointId: string;

//breadcrumb
unitIdBreadCrumb:string;


//Table ==================================================
public displayedColumns = ['name', 'pointName', 'update', 'delete', 'showDivisions'];
public dataSource = new MatTableDataSource<PointBuilding>();
@ViewChild(MatSort) sort: MatSort;
@ViewChild(MatPaginator) paginator: MatPaginator;

constructor(private router: Router,
  private repo: RepositoryService,
  private activeRoute: ActivatedRoute,
  private confirmDialogService: DialogService,
  public notificationsService: NotificationsService) { }

ngOnInit(): void {
  this.pointId = this.activeRoute.snapshot.params['id'];
  localStorage.setItem('pointId',this.pointId);
  this.unitIdBreadCrumb = localStorage.getItem('unitId');
  this.pointsBuildingsGet();
}
ngAfterViewInit(): void {
  this.applySortingAndPaging();
}

//Database =================================================
public pointsBuildingsGet = () => {
  this.repo.getData('api/pointbuilding/PointBuildingsGet?pointId=' + this.pointId)
    .subscribe(response => {
      this.isLoading = true;
      this.dataSource.data = response as PointBuilding[];
      console.log(response);
      this.isLoading = false;
    }, (error) => {
      console.log(error);
    })
}


public pointsBuildingsSearch = (name: string) => {
  this.isLoading = true;
  if (name != null) {
    this.repo.getData('api/pointbuilding/PointBuildingsSearch?name=' + name + '&pointId=' + this.pointId)
      .subscribe(response => {     
        this.dataSource.data = response as PointBuilding[];
        console.log(response);
        this.isLoading = false;
      }, (error) => {
        console.log(error);
      })
  }
}

public onDeleteClick = (id: string) => {
  const dialogRef = this.confirmDialogService.openConfirmDialog('هل انت متأكد من رغبتك في حذف المبنى؟');
  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      this.repo.delete('api/pointbuilding/PointBuildingDelete?id=' + id).subscribe(response => {
        this.pointsBuildingsGet();
        this.notificationsService.delete('تم حذف المبنى بنجاح');
      }, error => {
        console.log(error)
      })

    }
  });

}

//Helpers ===========================================
applySortingAndPaging(): void {
  this.dataSource.paginator = this.paginator;
  this.dataSource.sort = this.sort;
}

onKeyUp(event : any){
  console.log(event.target.value);
  if (event.target.value == '') {
   
    this.pointsBuildingsGet();
  }
}

public search = (value: string) => {
  //this.dataSource.filter = value.trim().toLocaleLowerCase();
  console.log(value)
  this.pointsBuildingsSearch(value)
}

}
