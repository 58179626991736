import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { User } from '../_models/User';
import { NotificationsService } from './notifications.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loggedIn = new BehaviorSubject<boolean>(false); // {1}
  //subscripe in header
  public getLoggedInType = new BehaviorSubject<string>('');
  public makeMeLoggedIn() {
    this.loggedIn.next(true);
  }
  get isLoggedIn() {
    return this.loggedIn.asObservable(); // {2}
  }
  public setLoggedIn(state:boolean) {
    this.loggedIn.next(state); // {2}
  }

  constructor(private router: Router, private route: ActivatedRoute, public notificationsService: NotificationsService,) { }


  // login(user: User) {
  //   if (user != null) {
  //     this.loggedIn.next(true);
  //     console.log(user);
  //     this.storeInLocalStorage(user);
  //     //Go to header
  //     this.getLoggedInType.next(user.userType.toString());

  //     const returnUrl = sessionStorage.getItem('returnUrl') || '/';
      
  //     if(returnUrl && returnUrl !== '/') {
  //       console.log(returnUrl);
  //       this.router.navigateByUrl(returnUrl);
  //     } else if (user.userType == 5) {
  //       this.router.navigateByUrl('/projects');
  //     } else {
  //       this.router.navigateByUrl('/requests');
  //     }
  //   }
  //   else {
  //     this.notificationsService.delete('تأكد من اسم المستخدم وكلمة المرور!');
  //   }
  // }

  login(user: User) {
    if (user != null) {
      this.loggedIn.next(true);
      console.log(user);
  
      // Determine privileges based on userType
      let privileges: number[] = [];
      
      switch(user.userType) {
        case 6: privileges = [0, 1]; break;
        case 7: privileges = [1, 2]; break;
        case 8: privileges = [0, 2]; break;
        case 9: privileges = [0, 1, 2]; break;
        default: privileges = []; // default to an empty array or set your default privileges here
      }
      
      // Save privileges in local storage
      if (privileges.length > 0) {
        user.userType = privileges[0]; // Set userType to the first number in privileges
      }
      
      localStorage.setItem('privileges', JSON.stringify(privileges));
      this.storeInLocalStorage(user);
      
      // Go to header
      this.getLoggedInType.next(user.userType.toString());
  
      const returnUrl = sessionStorage.getItem('returnUrl') || '/';
      
      if (returnUrl && returnUrl !== '/') {
        console.log(returnUrl);
        this.router.navigateByUrl(returnUrl);
      } else if (user.userType == 5) {
        this.router.navigateByUrl('/projects');
      } else {
        this.router.navigateByUrl('/requests');
      }
    }
  }
  

  logout() {
    this.removeFromLocalStorage();
    this.router.navigate(['/login']);
    this.loggedIn.next(false);
  }

  storeInLocalStorage = (user: User) => {
    localStorage.setItem("name", user.name);
    localStorage.setItem("userType", user.userType.toString());
    localStorage.setItem("userId", user.id);
    localStorage.setItem("isLoggedIn", '1');
  }

  removeFromLocalStorage = () => {
    localStorage.clear();
  }
}
