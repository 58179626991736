<!-- Loading Indicator -->
<div *ngIf="!roomTermsEntiresForm"
    style="display: flex; justify-content: center; align-items: center; background: rgb(37, 104, 180);">
    <mat-progress-bar color="secondary" mode="indeterminate">
    </mat-progress-bar>
</div>
<div class="row">
    <div class="col-md-12">
        <mat-card>
            <mat-card-title>
                <div class="gradient-Header">
                    <div class="gradient-child">
                        <h3>اضافة اعمال جديدة</h3>
                    </div>
                </div>

            </mat-card-title>
            <form [formGroup]="roomTermsEntiresForm" *ngIf="roomTermsEntiresForm" autocomplete="off" novalidate
                (ngSubmit)="onSubmit(roomTermsEntiresForm.value)">
                <mat-card-content>
                    <input type="hidden" formControlName="id">


                    <div class="row">
                        <!-- Line Here -->
                        <div class="col-md-12">
                            <mat-form-field>
                                <mat-label>الخط</mat-label>
                                <mat-select formControlName="lineId" id="lineId"
                                    (selectionChange)="linesSelectionChanged($event.value)">
                                    <mat-option [value]="0">--اختر--</mat-option>
                                    <mat-option *ngFor="let item of linesList" [value]="item.id">
                                        {{item.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6" *ngIf="roomTermsEntiresForm.value.lineId != 0">
                            <!-- اقسام وبنود -->
                            <mat-form-field>
                                <mat-label>القسم</mat-label>
                                <mat-select formControlName="roomDivisionId" id="roomDivisionId"
                                    (selectionChange)="roomDivisionSelectionChanged($event.value)">
                                    <mat-option [value]="0">--اختر--</mat-option>
                                    <mat-option *ngFor="let item of roomDivisionList" [value]="item.id">
                                        {{item.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field *ngIf="roomTermsEntiresForm.value.lineId != 0
                            && roomTermsEntiresForm.value.roomDivisionId != 0">
                                <mat-label>البند</mat-label>
                                <mat-select formControlName="roomTermId" id="roomTermId">
                                    <mat-option [value]="0">--اختر--</mat-option>
                                    <mat-option *ngFor="let item of roomTermList" [value]="item.id">
                                        {{item.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6" *ngIf="roomTermsEntiresForm.value.lineId != 0">
                            <!-- نوع غرفة ومحطات -->
                            <mat-form-field>
                                <mat-label>نوع الغرفة</mat-label>
                                <mat-select formControlName="roomTypeId" id="roomTypeId"
                                    (selectionChange)="roomTypeSelectionChanged($event.value)">
                                    <mat-option [value]="0">--اختر--</mat-option>
                                    <mat-option *ngFor="let item of roomTypeList" [value]="item.id">
                                        {{item.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field *ngIf="roomTermsEntiresForm.value.lineId != 0 
                            && roomTermsEntiresForm.value.roomDivisionId != 0">
                                <mat-label>المحطة</mat-label>
                                <mat-select formControlName="roomStationId" id="roomStationId">
                                    <mat-option [value]="0">--اختر--</mat-option>
                                    <mat-option *ngFor="let item of roomStationList" [value]="item.id">
                                        {{item.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <!-- ملاحظات -->
                        <div class="col-md-12">
                            <mat-form-field>
                                <input matInput type="text" placeholder="ملاحظات" formControlName="note" id="note">
                                <mat-error *ngIf="hasError('note', 'required')">هذا الحقل مطلوب</mat-error>
                                <mat-error *ngIf="hasError('note', 'maxlength')">الاسم اكثر من 200 حرف
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <!-- رفع الصورة -->
                        <div class="col-md-12">
                            <div class="col-md-3">
                                <mat-label>صورة الاستلام</mat-label>
                                <input type="file" #file accept=".jpg" id="fileUploader"
                                    (change)="selectFile(file.files)">
                            </div>
                            <div class="col-md-4">
                                <span class="upload" *ngIf="progress > 0">
                                    {{progress}}%
                                </span>
                                <span class="upload" *ngIf="message">
                                    {{message}}
                                </span>
                            </div>
                        </div>
                    </div>




                </mat-card-content>

                <mat-card-actions>


                    <div class="row">
                        <div class="col-md-12">
                            <button mat-raised-button color="primary" [disabled]="!roomTermsEntiresForm.valid">
                                {{ isUpdating == true ? 'تعديل الاعمال' : 'اضافة الاعمال' }}
                            </button>
                        </div>
                    </div>

                    <div class="row" style="margin-top: 5px;">
                        <div class="col-md-12">
                            <button mat-flat-button color="secondary" (click)="onCancel()">عودة</button>
                        </div>
                    </div>
                </mat-card-actions>
            </form>
        </mat-card>

    </div>
</div>
