<mat-nav-list>
    <!-- <a mat-list-item
        routerLink="/projects"
        (click)="onSidenavClose()">
        <mat-icon>home</mat-icon> <span class="nav-caption">المشاريع</span>
    </a> -->

    <!-- <mat-list-item [matMenuTriggerFor]="menu">
        <mat-icon>unfold_more</mat-icon>
        <a matline>Example</a>
    </mat-list-item> -->

    <a mat-button
        [matMenuTriggerFor]="menu">البيانات الاساسية <mat-icon>keyboard_arrow_down</mat-icon></a>
    <mat-menu #menu="matMenu">
        <button routerLink="/projects" (click)="onSidenavClose()"
            mat-menu-item>المشاريع</button>
    </mat-menu>
</mat-nav-list>
