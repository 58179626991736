<!-- Loading Indicator -->
<div *ngIf="!userForm"
    style="display: flex; justify-content: center; align-items: center; background: rgb(37, 104, 180);">
    <mat-progress-bar color="secondary" mode="indeterminate">
    </mat-progress-bar>
</div>

<div class="row">
    <div class="col-md-12">
        <mat-card>

            <mat-card-title>
                <div class="gradient-Header">
                    <div class="gradient-child">
                        <h3>اضافة مستخدم جديد</h3>
                    </div>
                </div>

            </mat-card-title>
            <form [formGroup]="userForm" *ngIf="userForm" autocomplete="off" novalidate
                (ngSubmit)="onSubmit(userForm.value)">
                <mat-card-content>
                    <input type="hidden" formControlName="id">

                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field>
                                <input matInput type="text" placeholder="الاسم" formControlName="name" id="name">
                                <mat-error *ngIf="hasError('name', 'required')">هذا الحقل مطلوب</mat-error>
                                <mat-error *ngIf="hasError('name', 'maxlength')">الاسم اكثر من 200 حرف</mat-error>
                            </mat-form-field>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field>
                                <input matInput type="text" placeholder="اسم المستخدم" formControlName="username" id="username">
                                <mat-error *ngIf="hasError('name', 'required')">هذا الحقل مطلوب</mat-error>
                                <mat-error *ngIf="hasError('name', 'maxlength')">الاسم اكثر من 200 حرف</mat-error>
                            </mat-form-field>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field>
                                <input matInput type="text" placeholder="كلمة المرور" formControlName="password" id="password">
                                <mat-error *ngIf="hasError('name', 'required')">هذا الحقل مطلوب</mat-error>
                            </mat-form-field>
                        </div>
                    </div>



                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field>
                                <mat-label>نوع المستخدم</mat-label>
                                <mat-select formControlName="userType" id="userType" multiple (selectionChange)="onUserTypeChange($event.value)">
                                    <mat-option *ngFor="let userType of enumuserTypes" [value]="userType.value">
                                        {{ userType.name }}
                                    </mat-option>
                                </mat-select>                                
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row" *ngIf="selectedUserType != 2">
                        <div class="col-md-12">
                            <mat-form-field>
                                <mat-label>المشاريع</mat-label>
                                <mat-select [compareWith]="compareFunction" formControlName="projects" multiple [(ngModel)]="selectedProjects">
                                    <mat-option *ngFor="let project of projectsList" [value]="project">
                                        {{project.name}}
                                    </mat-option>
                                </mat-select>
                              </mat-form-field>
                        </div>
                    </div>


                    <div class="row" *ngIf="selectedUserType == 2">
                        <div class="col-md-12">
                            <mat-form-field>
                                <mat-label>الوحدات</mat-label>
                                <mat-select [compareWith]="compareFunction" formControlName="units" multiple [(ngModel)]="selectedUnits">
                                    <mat-option *ngFor="let unit of unitsList" [value]="unit">
                                        {{unit.name}}
                                    </mat-option>
                                </mat-select>
                              </mat-form-field>
                        </div>
                    </div>




                </mat-card-content>

                <mat-card-actions>


                    <div class="row">
                        <div class="col-md-12">
                            <button mat-raised-button color="primary" [disabled]="!userForm.valid">
                                {{ isUpdating == true ? 'تعديل المستخدم' : 'اضافة المستخدم' }}
                            </button>
                        </div>
                    </div>

                    <div class="row" style="margin-top: 5px;">
                        <div class="col-md-12">
                            <button mat-flat-button color="secondary" (click)="onCancel()">عودة</button>
                        </div>
                    </div>
                </mat-card-actions>
            </form>
        </mat-card>

    </div>
</div>