import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'src/app/services/dialog.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { RepositoryService } from 'src/app/services/repository.service';
import { PointTermsEntries } from 'src/app/_models/PointTermsEntries';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-point-terms-list',
  templateUrl: './point-terms-list.component.html',
  styleUrls: ['./point-terms-list.component.css']
})
export class PointTermsListComponent implements OnInit {
  //Vars ==================================================
  isLoading = true;
  searchText: string;
  query: string;

  dateFrom: string;
  dateTo: string;
  dateSearchFrom: string;
  dateSearchTo: string;
  
  pointBuildingNames: string[] = [];
  unitNames: string[] = [];
  pointNames: string[] = [];


  baseUrl = environment.urlAddress;

  //Table ==================================================
  public displayedColumns = ['unitName','pointName','pointBuildingName','pointDivisionName',
  'pointTermName','unit', 'quantity','pointTermPrice','total', 'inspectorName', 'creationDate', 'note','photo', 'delete'];
  public dataSource = new MatTableDataSource<PointTermsEntries>();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private router: Router,
    private repo: RepositoryService,
    public datePipe: DatePipe,
    private activeRoute: ActivatedRoute,
    private confirmDialogService: DialogService,
    public notificationsService: NotificationsService) { }

  ngOnInit(): void {
   // this.pointsTermsEntriesGet();
    this.dateFrom = this.currentDate()
    this.dateTo = this.currentDate()

    this.dateSearchFrom = this.currentDate();
    this.dateSearchTo = this.currentDate();
   // this.pointsTermsEntriesGet();
   this.search('');
  }
  ngAfterViewInit(): void {
    this.applySortingAndPaging();
  }

  exportToExcel() {
    const startRowIndex = this.paginator.pageIndex * this.paginator.pageSize;
    const endRowIndex = startRowIndex + this.paginator.pageSize;
  
    // Slice the data based on the current page and page size
    const dataToExport = this.dataSource.data.slice(startRowIndex, endRowIndex);
  
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataToExport);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
    // Save the file.
    XLSX.writeFile(wb, 'data.xlsx');
  }

  changeFromDate(event: any) {
    this.dateSearchFrom = this.datePipe.transform(event, "yyyy-MM-dd");
  }
  changeToDate(event: any) {
    this.dateSearchTo = this.datePipe.transform(event, "yyyy-MM-dd");
  }
  currentDate() {
    const currentDate = new Date();
    return currentDate.toISOString().split('T')[0];
  }

  //Database =================================================
  public pointsTermsEntriesGet = () => {
    this.isLoading = true;
    this.repo.getData('api/pointTermEntries/PointTermEntrieGet?inspectorId='+localStorage.getItem('userId'))
      .subscribe(response => {
        this.dataSource.data = response as PointTermsEntries[];
        this.allData = this.dataSource.data;
        this.pointBuildingNames = Array.from(new Set(this.dataSource.data.map(item => item.pointBuildingName))); // <-- Add this line here
        this.unitNames = Array.from(new Set(this.dataSource.data.map(item => item.unitName)));
        this.pointNames = Array.from(new Set(this.dataSource.data.map(item => item.pointName)));    
        console.log(response);
        this.isLoading = false;
      }, (error) => {
        console.log(error);
      })
}

allData: PointTermsEntries[] = [];

filterByBuilding(buildingName: string) {
    if (buildingName) {
        this.dataSource.data = this.allData.filter(item => item.pointBuildingName === buildingName);
    } else {
        this.dataSource.data = this.allData; // Reset to show all if no specific building is selected
    }
}

filterByUnit(unitName: string) {
  if (unitName) {
      this.dataSource.data = this.dataSource.data.filter(item => item.unitName === unitName);
  } else {
      // Reset the data or fetch again if no filter is applied
      this.dataSource.data = this.allData; // Reset to show all if no specific building is selected
    }
}

filterByPoint(pointName: string) {
  if (pointName) {
      this.dataSource.data = this.dataSource.data.filter(item => item.pointName === pointName);
  } else {
      // Reset the data or fetch again if no filter is applied
      this.dataSource.data = this.allData; // Reset to show all if no specific building is selected
    }
}

  public pointsTermEntriesSearch = (note: string) => {
    this.isLoading = true;
    if (note != null) {
      this.repo.getData('api/pointTermEntries/PointTermEntrieSearch?note=' + note + '&dateFrom=' + this.dateSearchFrom + '&dateTo=' + this.dateSearchTo +'&userType='+localStorage.getItem('userType') + '&inspectorId='+localStorage.getItem('userId'))
        .subscribe(response => {
          this.dataSource.data = response as PointTermsEntries[];
          this.allData = this.dataSource.data;
          this.pointBuildingNames = Array.from(new Set(this.dataSource.data.map(item => item.pointBuildingName))); // <-- Add this line here
          this.unitNames = Array.from(new Set(this.dataSource.data.map(item => item.unitName)));
          this.pointNames = Array.from(new Set(this.dataSource.data.map(item => item.pointName)));      
          console.log(response);
          this.isLoading = false;
        }, (error) => {
          console.log(error);
        })
    }
  }


  public onDeleteClick = (id: string) => {
    const dialogRef = this.confirmDialogService.openConfirmDialog('هل انت متأكد من رغبتك في حذف الاعمال؟');
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.repo.delete('api/pointTermEntries/PointTermEntrieDelete?id=' + id).subscribe(response => {
          this.pointsTermsEntriesGet();
          this.notificationsService.delete('تم حذف الاعمال بنجاح');
        }, error => {
          console.log(error)
        })

      }
    });
  }



  //Helpers ===========================================
  applySortingAndPaging(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


  public search = (value: string) => {
    //this.dataSource.filter = value.trim().toLocaleLowerCase();
    console.log(value)
    if (value == null) { value = ''; }
    this.pointsTermEntriesSearch(value)
  }

  public clearSearch = (value: string) => {
    this.pointsTermsEntriesGet();
    this.dateFrom = this.currentDate()
    this.dateTo = this.currentDate()

    this.dateSearchFrom = this.currentDate();
    this.dateSearchTo = this.currentDate();
  }

}
