import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms'; // Import form-related modules
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-gdashboard-links',
  templateUrl: './gdashboard-links.component.html',
  styleUrls: ['./gdashboard-links.component.css']
})
export class GDashboardLinksComponent implements OnInit {
  linkForm: FormGroup; // Declare the form
  links: any[]; // Declare the links array

  constructor(private http: HttpClient, private formBuilder: FormBuilder) {
    // Initialize the form in the constructor
    this.linkForm = this.formBuilder.group({
      Name: ['', Validators.required], // Define form controls with validation
      ReportLink: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.getDashboardLinks();
  }

  getDashboardLinks() {
    this.http.get<any[]>('http://mohammedelsaed-001-site6.atempurl.com/gdashboard-links').subscribe(
      (data) => {
        console.log(data);
        this.links = data;
      },
      (error) => {
        console.error('Error fetching dashboard links:', error);
      }
    );
  }

  addLink() {
    const newLink = this.linkForm.value;
    this.http.post('http://mohammedelsaed-001-site6.atempurl.com/add-gdashboard-link', newLink).subscribe(
      () => {
        // Successfully added a link, update the table
        this.getDashboardLinks();
        // Reset the form after a successful submission
        this.linkForm.reset();
      },
      (error) => {
        console.error('Error adding link:', error);
      }
    );
  }
  
}
