import { RoomDivision } from './../../_models/RoomDivision';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { RepositoryService } from 'src/app/services/repository.service';
import { DialogService } from 'src/app/services/dialog.service';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-rooms-divisions-list',
  templateUrl: './rooms-divisions-list.component.html',
  styleUrls: ['./rooms-divisions-list.component.css']
})
export class RoomsDivisionsListComponent implements OnInit {

   //Vars ==================================================
   isLoading = true;
   searchText: string;
   query: string;
   lineId: string;
   unitId: string;
 
   //Table ==================================================
   public displayedColumns = ['name', 'lineName', 'update', 'delete', 'showroomTypes'];
   public dataSource = new MatTableDataSource<RoomDivision>();
   @ViewChild(MatSort) sort: MatSort;
   @ViewChild(MatPaginator) paginator: MatPaginator;
 
   constructor(private router: Router,
     private repo: RepositoryService,
     private activeRoute: ActivatedRoute,
     private confirmDialogService: DialogService,
     public notificationsService: NotificationsService) { }
 
   ngOnInit(): void {
     this.lineId = this.activeRoute.snapshot.params['id'];
     localStorage.setItem('lineId', this.lineId);
     this.unitId = localStorage.getItem('unitId');
     this.roomDivisionGet();
   }
   ngAfterViewInit(): void {
     this.applySortingAndPaging();
   }
 
   //Database =================================================
   public roomDivisionGet = () => {
     this.isLoading = true;
     this.repo.getData('api/roomdivision/roomDivisionsGet?lineId=' + this.lineId)
       .subscribe(response => {
         this.dataSource.data = response as RoomDivision[];
         console.log(response);
         this.isLoading = false;
       }, (error) => {
         console.log(error);
       })
   }
 
 
   public roomDivisionSearch = (name: string) => {
     this.isLoading = true;
     if (name != null) {
       this.repo.getData('api/roomdivision/roomDivisionsSearch?name=' + name + '&lineId=' + this.lineId)
         .subscribe(response => {
           this.dataSource.data = response as RoomDivision[];
           console.log(response);
           this.isLoading = false;
         }, (error) => {
           console.log(error);
         })
     }
   }
 
   public onDeleteClick = (id: string) => {
     const dialogRef = this.confirmDialogService.openConfirmDialog('هل انت متأكد من رغبتك في حذف القسم؟');
     dialogRef.afterClosed().subscribe(result => {
       if (result) {
         this.repo.delete('api/roomdivision/roomDivisionDelete?id=' + id).subscribe(response => {
           this.roomDivisionGet();
           this.notificationsService.delete('تم حذف القسم بنجاح');
         }, error => {
           console.log(error)
         })
 
       }
     });
 
   }
 
   //Helpers ===========================================
   applySortingAndPaging(): void {
     this.dataSource.paginator = this.paginator;
     this.dataSource.sort = this.sort;
   }
 
   onKeyUp(event: any) {
     console.log(event.target.value);
     if (event.target.value == '') {
 
       this.roomDivisionGet();
     }
   }
 
   public search = (value: string) => {
     //this.dataSource.filter = value.trim().toLocaleLowerCase();
     console.log(value)
     this.roomDivisionSearch(value)
   }

}
