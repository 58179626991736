import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from 'src/app/services/notifications.service';
import { RepositoryService } from 'src/app/services/repository.service';
import { LineCoordinate } from 'src/app/_models/LineCoordinate';

@Component({
  selector: 'app-lines-coordinates-form',
  templateUrl: './lines-coordinates-form.component.html',
  styleUrls: ['./lines-coordinates-form.component.css']
})
export class LinesCoordinatesFormComponent implements OnInit {

 //Vars ====================================
 linesCoordinatesForm: FormGroup;
 linesCoordinate: LineCoordinate;
 lineId: string;
 id: string;
 isUpdating: boolean = false;

 constructor(private router: Router,
   private repo: RepositoryService,
   private activeRoute: ActivatedRoute,
   public notificationsService: NotificationsService) { }
   
   ngOnInit(): void {
     this.createlinesCoordinatessForm();
     this.id = this.activeRoute.snapshot.params['id'];
     console.log(this.id);
     this.lineId = this.activeRoute.snapshot.params['lineId'];
     if (this.id != null) {
       this.isUpdating = true;
       this.linesCoordinatesGetById(this.id);
     }
   }


   public createlinesCoordinatessForm() {
     this.linesCoordinatesForm = new FormGroup({
       id: new FormControl(null),
       latitude: new FormControl(0),
       longitude: new FormControl(0),
       pressure: new FormControl(0),
       diameter: new FormControl(0),
       constant: new FormControl(0),
       station: new FormControl('', [Validators.required, Validators.maxLength(200)]),
     });
   }
 
   public onSubmit = (linesCoordinatesFormValue) => {
     if (this.linesCoordinatesForm.valid) {
       if (this.isUpdating) {
         this.linesCoordinatesUpdate(linesCoordinatesFormValue);
       }
       else {
         this.linesCoordinatesCreate(linesCoordinatesFormValue);
       }
     }
   }
 
   private linesCoordinatesCreate = (linesCoordinatesFormValue) => {
     let linesCoordinates: LineCoordinate = {
       latitude: linesCoordinatesFormValue.latitude,
       longitude: linesCoordinatesFormValue.longitude,
       pressure: linesCoordinatesFormValue.pressure,
       diameter: linesCoordinatesFormValue.diameter,
       station: linesCoordinatesFormValue.station,
       constant: linesCoordinatesFormValue.constant,
       lineId: this.lineId,
     }
     console.log(linesCoordinates);
     this.repo.create('api/LineCoordinate/LineCoordinateCreate', linesCoordinates)
       .subscribe(response => {
         this.notificationsService.success('تمت الاضافة بنجاح!');
         this.onCancel();
         console.log(response);
       }, (error) => {
         console.log(error);
       })
   }
 
   public linesCoordinatesGetById = (id: string) => {
     this.repo.getData('api/LineCoordinate/LineCoordinateGetById?id=' + id).subscribe(response => {
       this.linesCoordinate = response as LineCoordinate
       // this.linesCoordinatesForm.patchValue(this.linesCoordinates);
       this.linesCoordinatesForm.get('id').setValue(this.linesCoordinate.id);
       this.linesCoordinatesForm.get('latitude').setValue(this.linesCoordinate.latitude);
       this.linesCoordinatesForm.get('longitude').setValue(this.linesCoordinate.longitude);
       this.linesCoordinatesForm.get('pressure').setValue(this.linesCoordinate.pressure);
       this.linesCoordinatesForm.get('diameter').setValue(this.linesCoordinate.diameter);
       this.linesCoordinatesForm.get('station').setValue(this.linesCoordinate.station);
       this.linesCoordinatesForm.get('constant').setValue(this.linesCoordinate.constant);
       console.log(response);
     }, (error) => {
       console.log(error);
     })
   }
 
 
   public linesCoordinatesUpdate = (linesCoordinatesFormValue) => {
     let linesCoordinates: LineCoordinate = {
       id: linesCoordinatesFormValue.id,
       latitude: linesCoordinatesFormValue.latitude,
       longitude: linesCoordinatesFormValue.longitude,
       pressure: linesCoordinatesFormValue.pressure,
       diameter: linesCoordinatesFormValue.diameter,
       station: linesCoordinatesFormValue.station,
       constant: linesCoordinatesFormValue.constant,
       lineId: this.lineId,
     }
     console.log(linesCoordinates);
     this.repo.update('api/LineCoordinate/LineCoordinateUpdate', linesCoordinates)
       .subscribe(response => {
         this.notificationsService.success('تم التعديل بنجاح!');
         this.onCancel();
         console.log(response);
       }, (error) => {
         console.log(error);
       })
   }

   //Errors ======================================
 public hasError = (controlName: string, errorName: string) => {
   return this.linesCoordinatesForm.controls[controlName].hasError(errorName);
 }
 public onCancel = () => {
   this.router.navigate(['/lines-coordinates-list/' + this.lineId])
 }

}
