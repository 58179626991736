import { RequestType } from './../../_models/RequestType';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { RepositoryService } from 'src/app/services/repository.service';
import { DialogService } from 'src/app/services/dialog.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import * as XLSX from 'xlsx';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-request-types-list',
  templateUrl: './request-types-list.component.html',
  styleUrls: ['./request-types-list.component.css']
})
export class RequestTypesListComponent implements OnInit {

  //Vars ==================================================
  isLoading = true;
  searchText: string;
  projectId: string;
  query: string;
  fileName = 'ExcelSheet.xlsx';
  //Table ==================================================
  public displayedColumns = ['name', 'maxApprovalPeriod', 'projectName', 'update', 'delete'];
  public dataSource = new MatTableDataSource<RequestType>();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(private repo: RepositoryService,
    private activeRoute: ActivatedRoute,
    private confirmDialogService: DialogService,
    private notificationService: NotificationsService) { }

  ngOnInit(): void {
    this.projectId = this.activeRoute.snapshot.paramMap.get('projectId');
    this.requestTypesGet()
  }
  ngAfterViewInit(): void {
    this.applySortingAndPaging();
  }
  onKeyUp(event: any) {
    console.log(event.target.value);
    if (event.target.value == '') {

      this.requestTypesGet();
    }
  }


  //Database =================================================
  public requestTypesGet = () => {
    this.repo.getData('api/requestTypes/requestTypesGet?projectId=' + this.projectId)
      .subscribe(response => {
        this.isLoading = true;
        this.dataSource.data = response as RequestType[];
        console.log(response);
        this.isLoading = false;
      }, (error) => {
        console.log(error);
      })
  }


  public requestTypesSearch = (name: string) => {
    this.query = 'api/requestTypes/requestTypesSearch?name=' + name+'&projectId='+this.projectId;
    console.log(this.query);
    if (name != null) {
      this.repo.getData('api/requestTypes/requestTypesSearch?name=' + name)
        .subscribe(response => {
          this.isLoading = true;
          this.dataSource.data = response as RequestType[];
          console.log(response);
          this.isLoading = false;
        }, (error) => {
          console.log(error);
        })
    }
  }

  public onDeleteClick = (id: string) => {
    const dialogRef = this.confirmDialogService.openConfirmDialog('هل انت متأكد من رغبتك في حذف هذا النوع');
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.repo.delete('api/requestTypes/requestTypeDelete?id=' + id).subscribe(response => {
          this.requestTypesGet();
          this.notificationService.delete('تم حذف النوع بنجاح');
        }, error => {
          console.log(error)
        })

      }
    });

  }

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);

  }
  //Helpers ===========================================
  applySortingAndPaging(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public search = (value: string) => {
    //this.dataSource.filter = value.trim().toLocaleLowerCase();
    console.log(value)
    this.requestTypesSearch(value)
  }
}
