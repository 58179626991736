import { Point } from './../../_models/Point';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from 'src/app/services/notifications.service';
import { RepositoryService } from 'src/app/services/repository.service';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-points-list',
  templateUrl: './points-list.component.html',
  styleUrls: ['./points-list.component.css']
})
export class PointsListComponent implements OnInit {
  //Vars ==================================================
  isLoading = true;
  searchText: string;
  query: string;
  unitId: string;
  
  //Table ==================================================
  public displayedColumns = ['name', 'unitName', 'update', 'delete', 'showBuildings'];
  public dataSource = new MatTableDataSource<Point>();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private router: Router,
    private repo: RepositoryService,
    private activeRoute: ActivatedRoute,
    private confirmDialogService: DialogService,
    public notificationsService: NotificationsService) { }

  ngOnInit(): void {
    this.unitId = this.activeRoute.snapshot.params['id'];
    localStorage.setItem('unitId',this.unitId);
    this.pointsGet();
  }
  ngAfterViewInit(): void {
    this.applySortingAndPaging();
  }

  //Database =================================================
  public pointsGet = () => {
    this.isLoading = true;
    this.repo.getData('api/points/PointsGet?unitId=' + this.unitId)
      .subscribe(response => {  
        this.dataSource.data = response as Point[];
        console.log(response);
        this.isLoading = false;
      }, (error) => {
        console.log(error);
      })
  }


  public pointsSearch = (name: string) => {
    this.isLoading = true;
    if (name != null) {
      this.repo.getData('api/points/pointsSearch?name=' + name + '&unitId=' + this.unitId)
        .subscribe(response => {     
          this.dataSource.data = response as Point[];
          console.log(response);
          this.isLoading = false;
        }, (error) => {
          console.log(error);
        })
    }
  }

  public onDeleteClick = (id: string) => {
    const dialogRef = this.confirmDialogService.openConfirmDialog('هل انت متأكد من رغبتك في حذف النقطة؟');
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.repo.delete('api/points/pointDelete?id=' + id).subscribe(response => {
          this.pointsGet();
          this.notificationsService.delete('تم حذف النقطة بنجاح');
        }, error => {
          console.log(error)
        })

      }
    });

  }

  //Helpers ===========================================
  applySortingAndPaging(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  onKeyUp(event : any){
    console.log(event.target.value);
    if (event.target.value == '') {
     
      this.pointsGet();
    }
  }

  public search = (value: string) => {
    //this.dataSource.filter = value.trim().toLocaleLowerCase();
    console.log(value)
    this.pointsSearch(value)
  }

}
