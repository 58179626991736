<!-- Loading Indicator -->
<div *ngIf="!timeFrameForm"
    style="display: flex; justify-content: center; align-items: center; background: rgb(37, 104, 180);">
    <mat-progress-bar color="secondary" mode="indeterminate">
    </mat-progress-bar>
</div>

<div class="row">
    <div class="col-md-12">
        <mat-card>

            <mat-card-title>
                <div class="gradient-Header">
                    <div class="gradient-child">
                        <h3>اضافة مخطط زمني جديد</h3>
                    </div>
                </div>

            </mat-card-title>
            <form [formGroup]="timeFrameForm" *ngIf="timeFrameForm" autocomplete="off" novalidate
                (ngSubmit)="onSubmit(timeFrameForm.value)">
                <mat-card-content>
                    <input type="hidden" formControlName="id">


                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field>
                                <mat-label>نوع الوحدة</mat-label>
                                <mat-select formControlName="unitType" id="unitType">
                                    <mat-option *ngFor="let unitType of enumUnitTypes" [value]="unitType.value">
                                        {{ unitType.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>



                    <div class="row" *ngIf='timeFrameForm.value.unitType == 0'>
                        <div class="col-md-12">
                            <mat-form-field>
                                <mat-label>اسم المحطة او الخزان</mat-label>
                                <mat-select formControlName="point" id="pointName">
                                    <mat-option *ngFor="let point of pointsList" [value]="point.id">
                                        {{point.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>


                    <div class="row" *ngIf='timeFrameForm.value.unitType == 1'>
                        <div class="col-md-12">
                            <mat-form-field>
                                <mat-label>اسم الخط</mat-label>
                                <mat-select formControlName="line" id="lineName">
                                    <mat-option *ngFor="let point of linesList" [value]="point.id">
                                        {{point.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-md-12">

                            <mat-form-field>
                                <input matInput disabled [matDatepicker]="dp1" placeholder="التاريخ"
                                    formControlName="date" id="date">
                                <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                                <mat-datepicker #dp1 disabled="false"></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field>
                                <input matInput type="number" placeholder="المبلغ التراكمي"
                                    formControlName="accumulatedAmount" id="accumulatedAmount">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field>
                                <input matInput type="number" placeholder="النسبة التراكمية"
                                    formControlName="accumulatedPercentage" id="accumulatedPercentage">
                            </mat-form-field>
                        </div>
                    </div>


                </mat-card-content>

                <mat-card-actions>


                    <div class="row">
                        <div class="col-md-12">
                            <button mat-raised-button color="primary" [disabled]="!timeFrameForm.valid">
                                {{ isUpdating == true ? 'تعديل الوحدة' : 'اضافة مخطط زمني' }}
                            </button>
                        </div>
                    </div>

                    <div class="row" style="margin-top: 5px;">
                        <div class="col-md-12">
                            <button mat-flat-button color="secondary" (click)="onCancel()">عودة</button>
                        </div>
                    </div>
                </mat-card-actions>
            </form>
        </mat-card>

    </div>
</div>