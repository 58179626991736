
<div class="row">
    <div class="col-md-12">
        <mat-label>نموذج التقديم</mat-label>
        <input type="file" #file accept="application/pdf" style="width: 100%;" (change)="selectFile(file.files)">
    </div>
</div>


<div class="row" style="margin-top: 10px;margin-bottom: 10px;">
    <div class="col-md-12">
        <button type="button" style="width: 100%;" (click)="uploadFile()" class="btn btn-success">
            <i class="fa fa-save"></i>حفظ
        </button>
    </div>
</div>





<div mat-dialog-actions>
    <button mat-button style="width: 100%;" (click)="onNoClick()">اغلاق</button>
</div>