import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DownloaderService {

  constructor(private http: HttpClient) { }

  baseUrl = environment.urlAddress;

  downloadFile(id: string): any {
    console.log(this.baseUrl + '/RequestPdfFiles/' + id + '.pdf');
    return this.http.get(this.baseUrl + '/RequestPdfFiles/' + id + '.pdf', { responseType: 'blob' });
  }
}
