export class Constants {
    static enumUnitTypes = [
        { value: '0', name: 'نقطة' },
        { value: '1', name: 'خط' }
    ]

    static isLoggedIn = false;

    static enumReportType = [
        {value: '0',name:'اسبوعي'},
        {value: '1',name:'يومي'},
        // {value: '2',name:'داش بورد'},

    ]


    static enumUserTypes = [
        { value: '0', name: 'Project Manager' },
        { value: '1', name: 'SubAdmin' },
        { value: '2', name: 'Inspector' },
        { value: '3', name: 'Contractor' },
        { value: '4', name: 'Viewer' },
        { value: '5', name: 'Admin' },
    ]


    static enumEngineeringType = [
        { value: '0', name: 'مساحة' },
        { value: '1', name: 'مدني' },
        { value: '2', name: 'معماري' },
        { value: '3', name: 'ميكانيكا' },
        { value: '4', name: 'كهرباء' },
        { value: '5', name: 'اخرى' },
    ]


    static enumRequestReponse = [
        { value: '0', name: 'معتمد' },
        { value: '1', name: 'معتمد بملاحظات' },
        { value: '2', name: 'تلافي الملاحظات واعادة التقديم' },
        { value: '3', name: 'مرفوض' },
    ]

    static enumRequestSubmitLevel = [
        { value: '0', name: '1' },
        { value: '1', name: '2' },
        { value: '2', name: '3' },
        { value: '3', name: '4' },
        { value: '4', name: '5' },
        { value: '5', name: '6' },
        { value: '6', name: '7' },
        { value: '7', name: '8' },
        { value: '8', name: '9' },
        { value: '9', name: '10' },
    ]

}