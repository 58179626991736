import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-report-viewer',
  templateUrl: './report-viewer.component.html',
  styleUrls: ['./report-viewer.component.css'],
})

export class ReportViewerComponent implements OnInit {
  links: any[] = [];
  selectedLink: any = null;
  ReportLink: any = null;

  constructor(private route: ActivatedRoute, private http: HttpClient, private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.getDashboardLinks();

    }

  getDashboardLinks() {
    this.http.get<any[]>('http://mohammedelsaed-001-site6.atempurl.com/gdashboard-links').subscribe(
      (data) => {
        this.links = data;
        const id = this.route.snapshot.paramMap.get('id');
        const index = id ? +id : 0; // Use 0 as a default value if 'id' is not available
        
        // Use the index as an identifier
        this.selectedLink = this.links[index];
        this.selectedLink.ReportLink = this.sanitizer.bypassSecurityTrustHtml(this.selectedLink.ReportLink);
   
        console.log(this.links);
      },
      (error) => {
        console.error('Error fetching dashboard links:', error);
      }
    );
  }
}
