<h1>Map</h1>
<form [formGroup]="reportForm" *ngIf="reportForm" autocomplete="off" novalidate (ngSubmit)="onSubmit(reportForm.value)">
    <mat-checkbox formControlName="without">عرض بدون اعمال</mat-checkbox>
    <div class="row">
        <div class="col-md-12">
            <mat-form-field>
                <mat-label>اسم المشروع</mat-label>
                <mat-select formControlName="projectId" id="projectId">
                    <mat-option *ngFor="let project of projectsDropDownList" [value]="project.id">
                        {{project.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>


    <mat-form-field>
        <input matInput disabled [matDatepicker]="dp1" [(ngModel)]="dateFrom"
            (dateChange)="changeFromDate($event.value)" placeholder="تاريخ التقديم من">
        <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
        <mat-datepicker #dp1 disabled="false"></mat-datepicker>
    </mat-form-field>

    <button mat-raised-button color="primary">
        <mat-icon>search</mat-icon>
        بحث
    </button>

</form>


<div class="row">
    <div class="col-md-6">
        <img class="center" src="assets/legend.JPG">
    </div>
    <div *ngIf="mapData" class="col-md-6">
        <!-- <h5>زمن المشروع</h5>
        {{mapData.projectTime| percent : '1.2-2'}} -->

        <h5>نسبة المدة المنقضية</h5>
        {{mapData.elapsedTimePercentage| percent : '1.2-2'}}  
            
        <h5>نسبة الانجاز المخططة</h5>
        {{mapData.plannedTotalPercentage| percent : '1.2-2'}}

        <h5>نسبة الانجاز المنفذة</h5>
        {{mapData.actualTotalPercentage | percent : '1.2-2'}}
   
        <h5>SPI</h5>
        {{mapData.spi| percent : '1.2-2'}}
      
        <h5>SV</h5>
        {{mapData.sv| percent : '1.2-2'}}
       
    </div>
</div>


<!-- Loading Indicator -->
<div *ngIf="isLoading"
    style="display: flex; justify-content: center; align-items: center; background: rgb(37, 104, 180);">
    <mat-progress-bar color="secondary" mode="indeterminate">
    </mat-progress-bar>
</div>

<div id="map" style="height: 900px;"></div>