import { RoomTerm } from './../../_models/RoomTerm';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { RepositoryService } from 'src/app/services/repository.service';
import { DialogService } from 'src/app/services/dialog.service';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-rooms-terms-list',
  templateUrl: './rooms-terms-list.component.html',
  styleUrls: ['./rooms-terms-list.component.css']
})
export class RoomsTermsListComponent implements OnInit {

  //Vars ==================================================
  isLoading = true;
  searchText: string;
  query: string;
  roomDivisionId: string;
  unitId: string;
  //Breadcrumb
  roomBuildingIdForBreadCrumb: string;
  roomIdForBreadCrumb: string;
  unitIdForBreadCrumb: string;



  //Table ==================================================
  public displayedColumns = ['name', 'roomDivisionName', 'price','priority', 'maxQuantity','fraction',
    'unit', 'delete'];
  public dataSource = new MatTableDataSource<RoomTerm>();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private router: Router,
    private repo: RepositoryService,
    private activeRoute: ActivatedRoute,
    private confirmDialogService: DialogService,
    public notificationsService: NotificationsService) { }

  ngOnInit(): void {
    this.roomDivisionId = this.activeRoute.snapshot.params['id'];
    this.unitId = localStorage.getItem('unitId')
    localStorage.setItem('roomDivisionId', this.roomDivisionId);



    this.roomsTermsGet();
  }
  ngAfterViewInit(): void {
    this.applySortingAndPaging();
  }

  //Database =================================================
  public roomsTermsGet = () => {
    this.repo.getData('api/roomTerms/roomTermsGet?roomDivisionId=' + this.roomDivisionId)
      .subscribe(response => {
        this.isLoading = true;
        this.dataSource.data = response as RoomTerm[];
        console.log(response);
        this.isLoading = false;
      }, (error) => {
        console.log(error);
      })
  }


  public roomsTermsSearch = (name: string) => {
    this.isLoading = true;
    if (name != null) {
      this.repo.getData('api/roomTerms/roomTermsSearch?name=' + name + '&roomDivisionId=' + this.roomDivisionId)
        .subscribe(response => {
          this.dataSource.data = response as RoomTerm[];
          console.log(response);
          this.isLoading = false;
        }, (error) => {
          console.log(error);
        })
    }
  }

  public onDeleteClick = (id: string) => {
    const dialogRef = this.confirmDialogService.openConfirmDialog('هل انت متأكد من رغبتك في حذف البند؟');
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.repo.delete('api/roomTerms/RoomTermsDelete?id=' + id).subscribe(response => {
          this.roomsTermsGet();
          this.notificationsService.delete('تم حذف البند بنجاح');
        }, error => {
          console.log(error)
        })

      }
    });

  }

  //Helpers ===========================================
  applySortingAndPaging(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  onKeyUp(event: any) {
    console.log(event.target.value);
    if (event.target.value == '') {
      this.roomsTermsGet();
    }
  }

  public search = (value: string) => {
    //this.dataSource.filter = value.trim().toLocaleLowerCase();
    console.log(value)
    this.roomsTermsSearch(value)
  }

}
