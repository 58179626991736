import { PipeDivisionDropDownList } from './../../_models/PipeDivisionDropDownList';
import { PipeTermsEntries } from './../../_models/PipeTermsEntries';
import { PipeTermDropDownList } from './../../_models/PipeTermDropDownList';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { RepositoryService } from 'src/app/services/repository.service';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { NotificationsService } from 'src/app/services/notifications.service';
import { LineDropDownList } from 'src/app/_models/LineDropDownList';
import { RoomDivisionDropDownList } from 'src/app/_models/RoomDivisionDropDownList';

@Component({
  selector: 'app-pipe-terms-entries-form',
  templateUrl: './pipe-terms-entries-form.component.html',
  styleUrls: ['./pipe-terms-entries-form.component.css']
})
export class PipeTermsEntriesFormComponent implements OnInit {

  //Vars ====================================
  pipeTermsEntriesForm: FormGroup;
  linesList: LineDropDownList[] = [];
  pipeTermsdropDownList: PipeTermDropDownList[] = [];
  pipeDivisionList: PipeDivisionDropDownList[] = [];

  maxQuantityFromTerms: number = 0;
  availableQuantity: number = 0;
  termUnit: string;
  formData: FormData;
  selectedFiles: FileList;
  fileToUpload: File;

  baseUrl = environment.urlAddress;
  PipeTermsEntrie: PipeTermsEntries;
  isUpdating: boolean = false;
  id: string;
  public progress: number;
  entryId: string;
  isLoading: boolean = false;
  inspectorId: string = localStorage.getItem('userId')
  quantityMaxAllowedValue: number = 0;

  constructor(private router: Router,
    private repo: RepositoryService,
    private http: HttpClient,
    private activeRoute: ActivatedRoute,
    public notificationsService: NotificationsService) { }

  ngOnInit(): void {
    this.id = this.activeRoute.snapshot.params['id'];
    this.lineGetDropDownList();
  }

  linesSelectionChanged(event: any) {
    console.log(event);
    let selectedUnit = event;
    this.pipeDivisionDropDownList(event);

  }

  pipeDivisionSelectionChanged(event: any) {
    console.log(event);
    let selectedUnit = event;
    this.pipeTermsGetDropDownList(event);
  }

  ///===================================================================================================
  public lineGetDropDownList = () => {
    this.isLoading = true;
    //TODO:Add inspector Id
    console.log('lineGetDropDownList');
    this.repo.getData('api/roomDropDownList/lineForPipesTermsEntries?inspectorId=' + localStorage.getItem('userId')).subscribe(response => {
      this.linesList = response as LineDropDownList[]
      this.createPipesTermsEntriesForm();
      console.log('here');
      this.isLoading = false;
    }, (error) => {
      console.log(error);
    })
  }


  public pipeDivisionDropDownList = (lineId: string) => {
    this.isLoading = true;
    //TODO:Add inspector Id
    console.log('PipeDivisionDropDownList');
    this.repo.getData('api/Pipedivision/PipeDivisionDropDownList?lineId=' + lineId).subscribe(response => {
      this.pipeDivisionList = response as PipeDivisionDropDownList[]
      this.isLoading = false;
    }, (error) => {
      console.log(error);
    })
  }

  public pipeTermsGetDropDownList = (pipeDivisionId: string) => {
    this.isLoading = true;
    //TODO:Add inspector Id
    console.log('unitsGetDropDownList');
    this.repo.getData('api/PipeTerms/PipeTermsGetDropDownList?pipeDivisionId=' + pipeDivisionId).subscribe(response => {
      this.pipeTermsdropDownList = response as PipeTermDropDownList[]

      this.isLoading = false;
    }, (error) => {
      console.log(error);
    })
  }

  public createPipesTermsEntriesForm() {
    console.log('PipeTermsEntriesForm');
    this.pipeTermsEntriesForm = new FormGroup({
      lineId: new FormControl(0, [Validators.min(1)]),
      pipeDivisionId: new FormControl(0, [Validators.min(1)]),

      id: new FormControl(null),
      pipeTermId: new FormControl(0, [Validators.min(1)]),
      note: new FormControl(''),
      from: new FormControl(0, [Validators.min(0)]),
      to: new FormControl(0, [Validators.min(0)]),
      depth: new FormControl(1, [Validators.min(0)]),
      height: new FormControl(1, [Validators.min(0)]),
    });
  }

  public selectFile = (files) => {
    if (files.length === 0) {
      return;
    }
    this.fileToUpload = <File>files[0];
    this.formData = new FormData();
   // this.formData.append('file', this.fileToUpload, this.fileToUpload.name);
   this.formData.append('file', this.fileToUpload, this.fileToUpload.name.replace('jpeg','jpg').replace('png','jpg'));
  }

  public entryCreate = (entryFormValues) => {
    console.log(this.fileToUpload)
    if (this.fileToUpload == undefined) {
      this.notificationsService.delete('فضلا ادخل صورة الاستلام');
    console.log('here')
      
    console.log(this.fileToUpload)
    }
    else if (entryFormValues.from > entryFormValues.to) {
      this.notificationsService.delete('يجب ان يكون (من) اقل من (الى)');
    }
    else {
      this.isLoading = true;
      let term: PipeTermsEntries = {
        inspectorId: this.inspectorId,
        pipeTermId: entryFormValues.pipeTermId,
        note: entryFormValues.note,
        from: entryFormValues.from,
        to: entryFormValues.to,
        height: entryFormValues.height,
        depth:entryFormValues.depth
      }
      this.repo.create('api/pipeTermEntries/PipeTermEntrieCreate', term)
        .subscribe(response => {
          let obj = response as PipeTermsEntries
          this.isLoading = false;
          if (obj.note == 'invalid range') {
            console.log('invalid range')
            this.notificationsService.delete('الرينج موجود مسبقا');
          } else if (obj.note == 'invalid dependancy') {
            this.notificationsService.delete('فضلا ادخل الاعمال الخاصة بالبند السابق اولا');
          }
          else {
            this.entryId = obj.id;
            this.uploadFile()
          }

        }, (error) => {
          console.log(error);
        })
    }

  }



  //#region events
  pipeTermsSelectionChanged(event: any) {
    console.log(event);
    let selectedUnit = event;
    //this.PipesGetDropDownList(event);
  }

  public uploadFile() {
    this.http.post(this.baseUrl + '/api/PipeTermEntries/EntryUploadPhoto?entryId=' + this.entryId
      , this.formData, { reportProgress: true, observe: 'events' })
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response && !this.selectedFiles) {
          this.isLoading = false;
          this.notificationsService.success('تمت الاضافة بنجاح!');
          this.onCancel();
        }
      });
  }


  public onCancel = () => {
    this.router.navigate(['/pipes-terms-entries-list'])
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.pipeTermsEntriesForm.controls[controlName].hasError(errorName);
  }
}
