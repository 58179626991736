import { PointTermDropDownList } from './../../_models/PointTermDropDownList';
import { PointDivisionDropDownList } from './../../_models/PointDivisionDropDownList';
import { PointDropDownList } from './../../_models/PointDropDownList';
import { BuildingDropDownList } from './../../_models/BuildingDropDownList';
import { PointTermsEntries } from './../../_models/PointTermsEntries';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from 'src/app/services/notifications.service';
import { RepositoryService } from 'src/app/services/repository.service';
import { UnitDropDownList } from 'src/app/_models/UnitDropDownList';
import { TileStyler } from '@angular/material/grid-list/tile-styler';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-point-terms-entries-form',
  templateUrl: './point-terms-entries-form.component.html',
  styleUrls: ['./point-terms-entries-form.component.css']
})
export class PointTermsEntriesFormComponent implements OnInit {
  //Vars ====================================
  pointsTermsEntriesForm: FormGroup;

  unitsList: UnitDropDownList[];
  pointsList: PointDropDownList[] = [];
  pointbuildingList: BuildingDropDownList[] = [];
  pointDivisionList: PointDivisionDropDownList[] = [];
  pointTermsList: PointTermDropDownList[] = [];

  maxQuantityFromTerms: number = 0;
  availableQuantity: number = 0;
  termUnit: string;
  formData: FormData;
  selectedFiles: FileList;
  fileToUpload: File;

  baseUrl = environment.urlAddress;
  pointTermsEntrie: PointTermsEntries;
  isUpdating: boolean = false;
  id: string;
  public progress: number;
  entryId: string;
  isLoading: boolean = false;
  inspectorId: string = localStorage.getItem('userId')
  quantityMaxAllowedValue: number = 0;

  constructor(private router: Router,
    private repo: RepositoryService,
    private http: HttpClient,
    private activeRoute: ActivatedRoute,
    public notificationsService: NotificationsService) { }

  ngOnInit(): void {
    this.id = this.activeRoute.snapshot.params['id'];
    let userType = localStorage.getItem('userType');
    if (userType == '5') {
    this.unitsGetDropDownListForTermsForAdmin();
      
    }
    else {
    this.unitsGetDropDownList();
    }
  }

  public createPointsTermsEntriesForm() {
    console.log('pointTermsEntriesForm');
    this.pointsTermsEntriesForm = new FormGroup({
      id: new FormControl(null),
      quantity: new FormControl(0),
      note: new FormControl('', [Validators.maxLength(200)]),

      units: new FormControl(0, [Validators.min(1)]),
      points: new FormControl(0, [Validators.min(1)]),
      buildings: new FormControl(0, [Validators.min(1)]),
      division: new FormControl(0, [Validators.min(1)]),
      terms: new FormControl(0, [Validators.min(1)]),
    });


  }
  public onQuantityKeyPress(quantity: number) {
    if (quantity > this.quantityMaxAllowedValue) {
      return false;
    }
    else {
      return true;
    }
  }

  //#region events
  unitsSelectionChanged(event: any) {
    console.log(event);
    let selectedUnit = event;
    this.pointsGetDropDownList(event);
  }

  pointsSelectionChanged(event: any) {
    console.log(event);
    let selectedPoint = event;
    this.pointBuildingGetDropDownList(event);
  }

  pointsBuildingSelectionChanged(event: any) {
    console.log(event);
    let selectedPoint = event;
    this.pointDivisionGetDropDownList(event);
  }

  pointsDivisionSelectionChanged(event: any) {
    console.log(event);
    let selectedPoint = event;
    this.pointTermsGetDropDownList(event);
  }

  pointsTermsSelectionChanged(event: any) {
    console.log(event);
    this.PointTermGetAvailableQuantity(event);
    //this.PointTermGetMaxQuantity(event);
    this.PointTermGetUnit(event);
  }

  public selectFile = (files) => {
    if (files.length === 0) {
      return;
    }
   
    this.fileToUpload = <File>files[0];  
    this.formData = new FormData();
    this.formData.append('file', this.fileToUpload, this.fileToUpload.name.replace('jpeg','jpg').replace('png','jpg'));
  }

  public uploadFile() {
    this.http.post(this.baseUrl + '/api/pointTermEntries/EntryUploadPhoto?entryId=' + this.entryId
      , this.formData, { reportProgress: true, observe: 'events' })
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response && !this.selectedFiles) {
          console.log('here3')
          this.isLoading = false;
          this.notificationsService.success('تمت الاضافة بنجاح!');
          this.onCancel();
        }
      });
  }
  //#endregion
  //#region textValues
  public PointTermGetAvailableQuantity = (pointTermId: string) => {
    this.isLoading = true;
    const apiUrl = 'http://mohammedelsaed-001-site6.atempurl.com'; // Replace with your Express.js API URL
    const endpoint = `/pointTermGetAvailableQuantity?pointTermId=${pointTermId}`;
  
    this.http.get<number>(apiUrl + endpoint).subscribe(
      (response) => {
        this.availableQuantity = response;
        this.PointTermGetMaxQuantity(pointTermId);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  

  public PointTermGetMaxQuantity = (pointTermId: string) => {
    this.isLoading = true;
    const apiUrl = 'http://mohammedelsaed-001-site6.atempurl.com'; // Your API URL
    const endpoint = `/pointTermMaxQuantity/${pointTermId}`;
    
    this.http.get<{ maxQuantity: number }>(apiUrl + endpoint).subscribe(
      (response) => {
        this.maxQuantityFromTerms = response.maxQuantity; // Access maxQuantity from the response body
  
        console.log("maxQuantityFromTerms", this.maxQuantityFromTerms);
        console.log("availableQuantity", this.availableQuantity);
  
        this.isLoading = false;
        this.quantityMaxAllowedValue = this.maxQuantityFromTerms - this.availableQuantity;
  
        console.log("quantityMaxAllowedValue", this.quantityMaxAllowedValue);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  

  public PointTermGetUnit = (pointTermId: string) => {
    this.isLoading = true;
    //TODO:Add inspector Id
    this.repo.getData('api/pointterms/PointTermGetUnit?id=' + pointTermId).subscribe(response => {
      this.termUnit = response as string;
      this.isLoading = false;
    }, (error) => {
      console.log(error);
    })
  }

  //#endregion
  //#region database
  public unitsGetDropDownList = () => {
    this.isLoading = true;
    //TODO:Add inspector Id
    console.log('unitsGetDropDownList');
    this.repo.getData('api/units/unitsGetDropDownListForTerms?inspectorId='+localStorage.getItem('userId')).subscribe(response => {
      this.unitsList = response as UnitDropDownList[]
      this.createPointsTermsEntriesForm();
      this.isLoading = false;
    }, (error) => {
      console.log(error);
    })
  }


  public unitsGetDropDownListForTermsForAdmin = () => {
    this.isLoading = true;
    //TODO:Add inspector Id
    console.log('unitsGetDropDownList');
    this.repo.getData('api/units/unitsGetDropDownListForTermsForAdmin').subscribe(response => {
      this.unitsList = response as UnitDropDownList[]
      this.createPointsTermsEntriesForm();
      this.isLoading = false;
    }, (error) => {
      console.log(error);
    })
  }


  public pointsGetDropDownList = (unitId: string) => {
    this.isLoading = true;
    console.log('pointsGetDropDownList');
    this.repo.getData('api/points/PointsGetDropDownList?unitId=' + unitId).subscribe(response => {
      this.pointsList = response as PointDropDownList[]
      this.isLoading = false;
    }, (error) => {
      console.log(error);
    })
  }

  public pointBuildingGetDropDownList = (pointId: string) => {
    this.isLoading = true;
    console.log('pointsGetDropDownList');
    this.repo.getData('api/pointbuilding/PointBuildingGetDropDownList?pointId=' + pointId).subscribe(response => {
      this.pointbuildingList = response as BuildingDropDownList[]
      this.isLoading = false;
    }, (error) => {
      console.log(error);
    })
  }

  public pointDivisionGetDropDownList = (pointbuildingId: string) => {
    this.isLoading = true;
    console.log('pointsGetDropDownList');
    this.repo.getData('api/pointdivision/PointDivisionGetDropDownList?pointbuildingId=' + pointbuildingId).subscribe(response => {
      this.pointDivisionList = response as PointDivisionDropDownList[]
      this.isLoading = false;
    }, (error) => {
      console.log(error);
    })
  }

  public pointTermsGetDropDownList = (pointDivisionId: string) => {
    this.isLoading = true;
    console.log('pointDivisionGetDropDownList');
    this.repo.getData('api/pointterms/PointTermGetDropDownList?pointDivisionId=' + pointDivisionId).subscribe(response => {
      this.pointTermsList = response as PointTermDropDownList[]
      this.isLoading = false;
    }, (error) => {
      console.log(error);
    })
  }

  //#endregion


  //Errors ======================================
  public hasError = (controlName: string, errorName: string) => {
    return this.pointsTermsEntriesForm.controls[controlName].hasError(errorName);
  }
  public onCancel = () => {
    this.router.navigate(['/points-terms-entries-list'])
  }


  public onSubmit = (entryFormValues) => {
    
    if (this.pointsTermsEntriesForm.valid) {
      if (this.isUpdating) {
        // this.userUpdate(userFormValue);
      }
      else {
        if (entryFormValues.quantity > this.quantityMaxAllowedValue || entryFormValues.quantity == 0) {
          this.notificationsService.delete(' يجب ان تكون الكمية اقل من الكمية القصوى - الكمية العملة ولا تساوي صفر');
        } else if (this.fileToUpload == undefined) {
          this.notificationsService.delete('فضلا ادخل صورة الاستلام');
        }
        else {
          this.entryCreate(entryFormValues);
        }
      }
    }
  }

  private entryCreate = (entryFormValues) => {
    

    let term: PointTermsEntries = {
      inspectorId: this.inspectorId,
      pointTermId: entryFormValues.terms,
      quantity: entryFormValues.quantity,
      note: entryFormValues.note,
    }
    this.repo.create('api/pointTermEntries/PointTermEntrieCreate', term)
      .subscribe(response => {
        let obj = response as PointTermsEntries
        this.entryId = obj.id;
        this.uploadFile()

      }, (error) => {
        console.log(error);
      })
  }




}
