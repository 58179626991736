import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from 'src/app/services/notifications.service';
import { RepositoryService } from 'src/app/services/repository.service';
import { PointTerm } from 'src/app/_models/PointTerm';

@Component({
  selector: 'app-points-terms-form',
  templateUrl: './points-terms-form.component.html',
  styleUrls: ['./points-terms-form.component.css']
})
export class PointsTermsFormComponent implements OnInit {

 //Vars ====================================
 pointTermForm: FormGroup;
 pointTerm: PointTerm;
 pointDivisionId: string;
 id: string;
 isUpdating: boolean = false;

 constructor(private router: Router,
   private repo: RepositoryService,
   private activeRoute: ActivatedRoute,
   public notificationsService: NotificationsService) { }
 ngOnInit(): void {
   this.createpointsTermsForm();
   this.id = this.activeRoute.snapshot.params['id'];
   console.log(this.id);
   this.pointDivisionId = this.activeRoute.snapshot.params['pointDivisionId'];
   if (this.id != null) {
     this.isUpdating = true;
     this.pointTermGetById(this.id);
   }
 }

 //Form Create

 public createpointsTermsForm() {
   this.pointTermForm = new FormGroup({
     id: new FormControl(null),
     name: new FormControl('', [Validators.required, Validators.maxLength(200)]),
     price: new FormControl(0, [Validators.required, Validators.maxLength(200)]),
     unit: new FormControl('', [Validators.required, Validators.maxLength(200)]),
     maxQuantity: new FormControl(0, [Validators.required, Validators.maxLength(200)]),
   });
 }

 public onSubmit = (pointFormValue) => {
   if (this.pointTermForm.valid) {
     if (this.isUpdating) {
       this.pointTermUpdate(pointFormValue);
     }
     else {
       this.pointTermCreate(pointFormValue);
     }
   }
 }

 private pointTermCreate = (pointTermFormValue) => {
   let pointTerm: PointTerm = {
     name: pointTermFormValue.name,
     pointDivisionId: this.pointDivisionId,
     price: pointTermFormValue.price,
     unit: pointTermFormValue.unit,
     maxQuantity: pointTermFormValue.maxQuantity,
   }
   console.log(pointTerm);
   this.repo.create('api/pointterms/pointTermCreate', pointTerm)
     .subscribe(response => {
       this.notificationsService.success('تمت الاضافة بنجاح!');
       this.onCancel();
       console.log(response);
     }, (error) => {
       console.log(error);
     })
 }


 public pointTermGetById = (id: string) => {
   this.repo.getData('api/pointterms/pointTermGetById?id=' + id).subscribe(response => {
     this.pointTerm = response as PointTerm
     // this.pointForm.patchValue(this.point);
     this.pointTermForm.get('id').setValue(this.pointTerm.id);
     this.pointTermForm.get('name').setValue(this.pointTerm.name);
     this.pointTermForm.get('price').setValue(this.pointTerm.price);
     this.pointTermForm.get('unit').setValue(this.pointTerm.unit);
     this.pointTermForm.get('maxQuantity').setValue(this.pointTerm.maxQuantity);
     console.log(response);
   }, (error) => {
     console.log(error);
   })
 }


 public pointTermUpdate = (pointTermFormValue) => {
   let pointTerm: PointTerm = {
     id: pointTermFormValue.id,
     name: pointTermFormValue.name,
     price: pointTermFormValue.price,
     unit: pointTermFormValue.unit,
     maxQuantity: pointTermFormValue.maxQuantity,
     pointDivisionId: this.pointDivisionId,
   }
   console.log(pointTerm);
   this.repo.update('api/pointterms/pointTermUpdate', pointTerm)
     .subscribe(response => {
       this.notificationsService.success('تم التعديل بنجاح!');
       this.onCancel();
       console.log(response);
     }, (error) => {
       console.log(error);
     })
 }

 //Errors ======================================
 public hasError = (controlName: string, errorName: string) => {
   return this.pointTermForm.controls[controlName].hasError(errorName);
 }
 public onCancel = () => {
   this.router.navigate(['/points-terms/' + this.pointDivisionId])
 }

}
