<div class="row">
    <div class="col-md-12">
        <mat-card>

            <mat-card-title>
                <div class="gradient-Header">
                    <div class="gradient-child">
                        <h3>اضافة مشروع جديد</h3>
                    </div>
                </div>

            </mat-card-title>
            <form [formGroup]="projectForm" autocomplete="off" novalidate (ngSubmit)="onSubmit(projectForm.value)">
                <mat-card-content>
                    <input type="hidden" formControlName="id">

                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field>
                                <input matInput type="text" placeholder="اسم المشروع" formControlName="name" id="name">
                                <mat-error *ngIf="hasError('name', 'required')">هذا الحقل مطلوب</mat-error>
                                <mat-error *ngIf="hasError('name', 'maxlength')">الاسم اكثر من 200 حرف</mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field>
                                <input matInput type="number" placeholder="قيمة التعاقد" formControlName="contractValue"
                                    id="contractValue">
                                <mat-error *ngIf="hasError('contractValue', 'required')">هذا الحقل مطلوب</mat-error>
                                <mat-error *ngIf="hasError('contractValue', 'maxlength')">الاسم اكثر من 200 حرف</mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field>
                                <input matInput type="text" placeholder="اسم العميل" formControlName="clientName"
                                    id="clientName">
                                <mat-error *ngIf="hasError('clientName', 'required')">هذا الحقل مطلوب</mat-error>
                                <mat-error *ngIf="hasError('clientName', 'maxlength')">الاسم اكثر من 200 حرف</mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field>
                                <input matInput type="text" placeholder="اسم الاستشاري" formControlName="consultantName"
                                    id="consultantName">
                                <mat-error *ngIf="hasError('consultantName', 'required')">هذا الحقل مطلوب</mat-error>
                                <mat-error *ngIf="hasError('consultantName', 'maxlength')">الاسم اكثر من 200 حرف
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field>
                                <input matInput type="text" placeholder="اسم المتعاقد" formControlName="contractorName"
                                    id="contractorName">
                                <mat-error *ngIf="hasError('contractorName', 'required')">هذا الحقل مطلوب</mat-error>
                                <mat-error *ngIf="hasError('contractorName', 'maxlength')">الاسم اكثر من 200 حرف
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">

                            <mat-form-field>
                                <input matInput disabled [matDatepicker]="dp1" placeholder="تاريخ بداية المشروع"
                                    formControlName="startDate" id="startDate">
                                <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                                <mat-datepicker #dp1 disabled="false"></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field>
                                <input matInput disabled [matDatepicker]="dp2" placeholder="تاريخ نهاية المشروع"
                                    formControlName="endDate" id="endDate">
                                <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                                <mat-datepicker #dp2 disabled="false"></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>


                    <div class="row">
                        <!-- رفع الصورة -->
                        <div class="col-md-12">
                            <div class="col-md-3">
                                <mat-label>صورة العميل</mat-label>
                                <input type="file" #file accept=".jpg" id="fileUploader"
                                    (change)="selectClientPage(file.files)">
                            </div>
                            <div class="col-md-4">
                                <span class="upload" *ngIf="progress > 0">
                                    {{progress}}%
                                </span>
                                <span class="upload" *ngIf="message">
                                    {{message}}
                                </span>
                            </div>
                        </div>
                    </div>



                    <div class="row">
                        <!-- رفع الصورة -->
                        <div class="col-md-12">
                            <div class="col-md-3">
                                <mat-label>صورة الاستشاري</mat-label>
                                <input type="file" #file accept=".jpg" id="fileUploader"
                                    (change)="selectContractorPage(file.files)">
                            </div>
                            <div class="col-md-4">
                                <span class="upload" *ngIf="progress > 0">
                                    {{progress}}%
                                </span>
                                <span class="upload" *ngIf="message">
                                    {{message}}
                                </span>
                            </div>
                        </div>
                    </div>





                </mat-card-content>

                <mat-card-actions>


                    <div class="row">
                        <div class="col-md-12">
                            <button mat-raised-button color="primary" [disabled]="!projectForm.valid">
                                {{ isUpdating == true ? 'تعديل المشروع' : 'اضافة مشروع' }}
                            </button>
                        </div>
                    </div>

                    <div class="row" style="margin-top: 5px;">
                        <div class="col-md-12">
                            <button mat-flat-button color="secondary" (click)="onCancel()">عودة</button>
                        </div>
                    </div>
                </mat-card-actions>
            </form>
        </mat-card>

    </div>
</div>
