import { Line } from './../../_models/Line';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'src/app/services/dialog.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { RepositoryService } from 'src/app/services/repository.service';

@Component({
  selector: 'app-lines-list',
  templateUrl: './lines-list.component.html',
  styleUrls: ['./lines-list.component.css']
})
export class LinesListComponent implements OnInit {
  //Vars ==================================================
  isLoading = true;
  searchText: string;
  query: string;
  unitId: string;

  //Table ==================================================
  public displayedColumns = ['name', 'unitName', 'update', 'delete','showCoordinates',
  'showPipesDivisions','showRoomsDivisions','showRoomsTypes'];
  public dataSource = new MatTableDataSource<Line>();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private router: Router,
    private repo: RepositoryService,
    private activeRoute: ActivatedRoute,
    private confirmDialogService: DialogService,
    public notificationsService: NotificationsService) { }

  ngOnInit(): void {
    this.unitId = this.activeRoute.snapshot.params['id'];
    localStorage.setItem('unitId', this.unitId);
    this.linesGet();
  }

  public linesGet = () => {
    this.isLoading = true;
    this.repo.getData('api/lines/linesGet?unitId=' + this.unitId)
      .subscribe(response => {
        this.dataSource.data = response as Line[];
        console.log(response);
        this.isLoading = false;
      }, (error) => {
        console.log(error);
      })
  }

  public linesSearch = (name: string) => {
    this.isLoading = true;
    if (name != null) {
      this.repo.getData('api/lines/linesSearch?name=' + name + '&unitId=' + this.unitId)
        .subscribe(response => {     
          this.dataSource.data = response as Line[];
          console.log(response);
          this.isLoading = false;
        }, (error) => {
          console.log(error);
        })
    }
  }


  public onDeleteClick = (id: string) => {
    const dialogRef = this.confirmDialogService.openConfirmDialog('هل انت متأكد من رغبتك في حذف الخط؟');
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.repo.delete('api/lines/lineDelete?id=' + id).subscribe(response => {
          this.linesGet();
          this.notificationsService.delete('تم حذف الخط بنجاح');
        }, error => {
          console.log(error)
        })

      }
    });

  }


  //Helpers ===========================================
  applySortingAndPaging(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  onKeyUp(event : any){
    console.log(event.target.value);
    if (event.target.value == '') {
     
      this.linesGet();
    }
  }

  public search = (value: string) => {
    //this.dataSource.filter = value.trim().toLocaleLowerCase();
    console.log(value)
    this.linesSearch(value)
  }

}
