import { Constants } from 'src/app/Constants';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Output() public sidenavToggle = new EventEmitter();
  userType: string;
  isLoggedIn$: Observable<boolean>;
  privileges: number[] = [];
  privilegeRoles: Map<number, string> = new Map<number, string>();

  constructor(private router: Router, private authService: AuthService) {
    // Define the mapping of privilege values to roles
    this.privilegeRoles.set(0, 'Project Manager');
    this.privilegeRoles.set(1, 'SubAdmin');
    this.privilegeRoles.set(2, 'Inspector');
  }

  ngOnInit(): void {
    console.log("navbar loaded!")
    this.isLoggedIn$ = this.authService.isLoggedIn;
    this.authService.getLoggedInType.subscribe(response => {
      this.userType = response;
      this.loadPrivileges();
    });
    console.log("Header: " + this.userType);
    if (this.userType == '') {
      this.userType = localStorage.getItem('userType');
      console.log(this.userType);
      this.loadPrivileges();
    }
    console.log(this.isLoggedIn$);
  }

  ngAfterViewInit(): void {
    
  }

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  }

  public logout() {
    this.authService.logout();
  }

  private loadPrivileges() {
    const privilegesString = localStorage.getItem('privileges');
    if (privilegesString) {
      this.privileges = JSON.parse(privilegesString);
    }
  }

  // Function to handle role selection
  public selectRole(privilege: number) {
    // Update the userType in local storage
    localStorage.setItem('userType', privilege.toString());
    // Refresh the page
    window.location.reload();
  }
}
