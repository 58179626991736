<!-- Loading Indicator -->
<div *ngIf="isLoading"
    style="display: flex; justify-content: center; align-items: center; background: rgb(37, 104, 180);">
    <mat-progress-bar color="secondary" mode="indeterminate">
    </mat-progress-bar>
</div>

<div class="gradient-Header">
    <div class="gradient-child">
        <h3>الطلبات</h3>
    </div>
</div>


<div>
    <div class="row" style="margin-top: 10px;">
        <div class="col-md-2">

            <mat-form-field>
                <input matInput (keydown.enter)="search(searchText)" [(ngModel)]="searchText" type="text"
                    placeholder="بحث">
            </mat-form-field>



        </div>

        <div class="col-md-2">
            <mat-form-field *ngIf="usertype=='0' ||usertype=='5'">
                <mat-label>اسم المشروع</mat-label>
                <mat-select (selectionChange)="changeProject($event.value)">
                    <mat-option *ngFor="let project of projectsList" [value]="project.id">
                        {{project.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-md-2">
            <mat-form-field>
                <mat-label>نوع الطلب</mat-label>
                <mat-select (selectionChange)="changeType($event.value)">
                    <mat-option *ngFor="let requestType of requestTypesList" [value]="requestType.id">
                        {{requestType.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-md-2">
            <mat-form-field>
                <input matInput disabled [matDatepicker]="dp1" [(ngModel)]="dateFrom"
                    (dateChange)="changeFromDate($event.value)" placeholder="تاريخ التقديم من">
                <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                <mat-datepicker #dp1 disabled="false"></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="col-md-2">
            <mat-form-field>
                <input matInput disabled [matDatepicker]="dp2" [(ngModel)]="dateTo"
                    (dateChange)="changeToDate($event.value)" placeholder="تاريخ التقديم الى">
                <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                <mat-datepicker #dp2 disabled="false"></mat-datepicker>
            </mat-form-field>
        </div>


        <div class="col-md-2">

            <button mat-raised-button (click)="search(searchText)" color="primary">
                <mat-icon>search</mat-icon>
                بحث
            </button>

        </div>
        <div class="col-md-2">

            <button style="margin-top: 10px;" mat-raised-button (click)="clearSearch(searchText)" color="info">
                <mat-icon>search</mat-icon>
                عرض الكل
            </button>
        </div>

        <!-- <button (click)="exportToExcel()">Export The Report</button> -->
    </div>

    <div class="row" *ngIf="usertype=='0'|| usertype=='1'|| usertype=='3'||usertype=='2'||usertype=='5'">
        <div class="col-md-12">
            <mat-checkbox (change)="getAllUnResponsedRequests($event.checked)" [(ngModel)]="unResponsedchecked"
                [checked]="unResponsedchecked" color="primary">عرض الطلبات الغير مردود
                عليها</mat-checkbox>
        </div>
    </div>
</div>



<div class="row" style="margin-top: 10px;">
    <div class="col-md-3 mb-2">
        <button [disabled]="usertype=='0' || usertype=='1'||usertype=='2'||usertype=='4'||usertype=='5'"
            [routerLink]="['/requests-form']" mat-raised-button color="primary">
            <mat-icon>add</mat-icon>
            اضافة
        </button>
    </div>
</div>

<div class="table-responsive">


    <div class="grid-table">
        <div class="thead">
            <div class="tr">
                <div class="td">كود</div>
                <div class="td">اسم الطلب</div>
                <div class="td">من</div>
                <div class="td">الى</div>
                <div class="td">اسم النقطة</div>
                <div class="td">النوع</div>
                <div class="td">مرحلة التقديم</div>
                <div class="td">رقم الطلب</div>
                <!-- <div class="td">اسم المشروع</div> -->
                <div class="td">نوع الطلب</div>
                <div class="td">تاريخ التقديم</div>
                <div class="td">تاريخ النهاية</div>
                <!-- <div class="td">اسم مقدم الطلب</div> -->
                <div class="td">القائم بالاستلام</div>
                <div class="td">رد القائم بالاستلام</div>
                <div class="td">تاريخ رد القائم بالاستلام</div>
                <div class="td">ملاحظات القائم بالاستلام</div>
                <div class="td">مدير المشروع</div>
                <div class="td">رد مدير المشروع</div>
                <div class="td">تاريخ رد مدير المشروع</div>
                <div class="td">ملاحظات مدير المشروع</div>
                <div class="td"></div>
                <div class="td"></div>
                <div class="td"></div>
                <div class="td"></div>
                <div class="td"></div>
            </div>
        </div>
        <div class="tbody">
            <form class="tr" (submit)="recordSubmit(fg)" [formGroup]="fg"
                *ngFor="let fg of requestForms.controls; let i=index;">
                <div class="td">
                    <input disabled class="form-control cellCode" formControlName="id">
                </div>
                <div class="td">
                    <input disabled class="form-control cellsowide" formControlName="title">
                </div>
                <div class="td">
                    <input disabled class="form-control cellRequest" formControlName="requestFrom">
                </div>
                <div class="td">
                    <input disabled class="form-control cellRequest" formControlName="requestTo">
                </div>
                <div class="td">
                    <input disabled class="form-control cellDate" formControlName="pointName">
                </div>

                <div class="td">
                    <select class="form-control" formControlName="engineeringType">
                        <option *ngFor="let item of engineeringType" value="{{item.value}}">{{item.name}}</option>
                    </select>
                </div>

                <div class="td">
                    <input disabled class="form-control cellSubmitLevel" formControlName="submitLevel">
                </div>
                <div class="td">
                    <input disabled class="form-control cellRequestNumber" formControlName="requestNumber">
                </div>
                <!-- <div class="td">
                    <input disabled class="form-control cellProjectName" formControlName="projectName">
                </div> -->
                <div class="td">
                    <input disabled class="form-control cellRequestName" formControlName="requestName">
                </div>
                <div class="td">
                    <input disabled class="form-control cellDate" formControlName="submitDate">
                </div>
                <div class="td">
                    <input disabled class="form-control cellDate" formControlName="dueDate">
                </div>
                <!-- <div class="td">
                    <input disabled class="form-control cellwide" formControlName="contractorName">
                </div> -->

                <div class="td">
                    <select class="form-control" formControlName="inspectorName">
                        <option *ngFor="let item of inspectorList" value="{{item.id}}">{{item.name}}</option>
                    </select>
                </div>

                <div class="td">
                    <select class="form-control" formControlName="inspectorResponse">
                        <option *ngFor="let item of enumResponse" value="{{item.value}}">{{item.name}}</option>
                    </select>
                </div>

                <div class="td">
                    <input disabled class="form-control cellDate" formControlName="inspectorResponseDate">
                </div>

                <div class="td">
                    <input class="form-control cellNote" formControlName="inspectorNote">
                </div>


                <div class="td">
                    <select class="form-control" formControlName="projectManagerName">
                        <option *ngFor="let item of projectManagerList" value="{{item.id}}">{{item.name}}</option>
                    </select>
                </div>

                <div class="td">
                    <select class="form-control" formControlName="projectManagerResponse">
                        <option *ngFor="let item of enumResponse" value="{{item.value}}">{{item.name}}</option>
                    </select>
                </div>

                <div class="td">
                    <input disabled class="form-control cellDate" formControlName="projectManagerResponseDate">
                </div>

                <div class="td">
                    <input class="form-control cellNote" formControlName="projectManagerNote">
                </div>

                <div class="td">
                    <button [disabled]="usertype=='3'||usertype=='5'||usertype=='4'" type="submit"
                        class="btn btn-success">
                        <i class="fa fa-save"></i>حفظ
                    </button>
                </div>
                <div class="td">
                    <button type="button" [routerLink]="['/request-attachments/'+fg.value.id]" class="btn btn-primary">
                        <i class="fa fa-save"></i>عرض المرفقات
                    </button>
                </div>

                <div class="td">
                    <a type="button" class="btn btn-info" href="{{baseUrl+'/RequestPdfFiles/'}}{{fg.value.id}}.pdf"
                        download="fileName">تحميل</a>
                </div>

                <div class="td">
                    <button type="button" (click)="openDialog(fg.value.id)" class="btn btn-primary">
                        <i class="fa fa-save"></i>رفع مرفق
                    </button>
                </div>

                <!-- <div class="td">
                    <div class="col-md-6">
                        <div class="col-md-3">
                            <mat-label>نموذج التقديم</mat-label>
                            <input type="file" #file accept="application/pdf" (change)="selectFile(file.files,fg.value.id)">
                        </div>
                        <div class="col-md-4">
                            <span class="upload" *ngIf="progress > 0">
                                {{progress}}%
                            </span>
                            <span class="upload" *ngIf="message">
                                {{message}}
                            </span>
                        </div>
                    </div>
                </div> -->

                <div class="td">
                    <button type="button"
                        *ngIf="usertype=='0' || usertype=='1'||usertype=='2'||usertype=='4'|| (usertype=='3' &&  fg.getRawValue().inspectorResponse == null)"
                        class="btn btn-danger" (click)="onDeleteClick(fg.value.id)" download="fileName">حذف</button>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- Paginator -->
<!-- <mat-paginator [length]="100" [pageSize]="5" showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]">
</mat-paginator> -->