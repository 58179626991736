import { MatTableDataSource } from '@angular/material/table';
import { NotificationsService } from './../../services/notifications.service';
import { DialogService } from './../../services/dialog.service';
import { RepositoryService } from './../../services/repository.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { LineCoordinate } from './../../_models/LineCoordinate';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-lines-coordinates-list',
  templateUrl: './lines-coordinates-list.component.html',
  styleUrls: ['./lines-coordinates-list.component.css']
})
export class LinesCoordinatesListComponent implements OnInit {

 //Vars ==================================================
 isLoading = true;
 searchText: string;
 query: string;
 lineId: string;
 unitId: string;

 //Table ==================================================
 public displayedColumns = [ 'lineName','constant','latitude','longitude','pressure','diameter','station', 'update', 'delete'];
 public dataSource = new MatTableDataSource<LineCoordinate>();
 @ViewChild(MatSort) sort: MatSort;
 @ViewChild(MatPaginator) paginator: MatPaginator;

 constructor(private router: Router,
   private repo: RepositoryService,
   private activeRoute: ActivatedRoute,
   private confirmDialogService: DialogService,
   public notificationsService: NotificationsService) { }

 ngOnInit(): void {
   this.lineId = this.activeRoute.snapshot.params['id'];
   localStorage.setItem('lineId', this.lineId);
   this.unitId = localStorage.getItem('unitId');
   this.linesCoordinatesGet();
 }

 public linesCoordinatesGet = () => {
   this.isLoading = true;
   this.repo.getData('api/LineCoordinate/LineCoordinatesGet?lineId=' + this.lineId)
     .subscribe(response => {
       this.dataSource.data = response as LineCoordinate[];
       console.log(response);
       this.isLoading = false;
     }, (error) => {
       console.log(error);
     })
 }

 public linesCoordinatesSearch = (name: string) => {
   this.isLoading = true;
   if (name != null) {
     this.repo.getData('api/LineCoordinate/LineCoordinatesSearch?name=' + name + '&lineId=' + this.lineId)
       .subscribe(response => {
         this.dataSource.data = response as LineCoordinate[];
         console.log(response);
         this.isLoading = false;
       }, (error) => {
         console.log(error);
       })
   }
 }

 public onDeleteClick = (id: string) => {
   const dialogRef = this.confirmDialogService.openConfirmDialog('هل انت متأكد من رغبتك في حذف الاحداثيات؟');
   dialogRef.afterClosed().subscribe(result => {
     if (result) {
       this.repo.delete('api/LineCoordinate/LineCoordinateDelete?id=' + id).subscribe(response => {
         this.linesCoordinatesGet();
         this.notificationsService.delete('تم حذف الاحداثي بنجاح');
       }, error => {
         console.log(error)
       })

     }
   });

 }


 //Helpers ===========================================
 applySortingAndPaging(): void {
   this.dataSource.paginator = this.paginator;
   this.dataSource.sort = this.sort;
 }

 onKeyUp(event: any) {
   console.log(event.target.value);
   if (event.target.value == '') {

     this.linesCoordinatesGet();
   }
 }

 public search = (value: string) => {
   //this.dataSource.filter = value.trim().toLocaleLowerCase();
   console.log(value)
   this.linesCoordinatesSearch(value)
 }

}
