<div *ngIf="!form || isLoading" style="display: flex; justify-content: center; align-items: center; background: rgb(37, 104, 180);">
    <mat-progress-bar color="secondary" mode="indeterminate">
    </mat-progress-bar>
</div>
<mat-card>
    <mat-card-title></mat-card-title>
    <img src="/assets/single_user.png" class="mx-auto d-block">
    <mat-card-content>
        <form [formGroup]="form" (ngSubmit)="login(form.value)">
            <p>
                <mat-form-field>
                    <input type="text" matInput placeholder="اسم المستخدم" formControlName="username">
                    <mat-error *ngIf="hasError('username', 'required')">هذا الحقل مطلوب</mat-error>
                </mat-form-field>
            </p>

            <p>
                <mat-form-field>
                    <input type="password" matInput placeholder="كلمة المرور" formControlName="password">
                    <mat-error *ngIf="hasError('password', 'required')">هذا الحقل مطلوب</mat-error>
                </mat-form-field>
            </p>

            <p *ngIf="error" class="error">
                {{ error }}
            </p>

            <div class="button">
                <button type="submit" mat-flat-button color="primary" [disabled]="!form.valid" mat-button>تسجيل
                    دخول</button>
            </div>

        </form>
    </mat-card-content>
</mat-card>