<nav *ngIf="isLoggedIn$ | async" class="navbar navbar-expand-lg navbar-dark bg-dark" style="direction: ltr;">
    <!-- <a class="navbar-brand">PMIS</a> -->
    <a href="#" class="navbar-left"><img src="/assets/logo.jpg" style="width: 100px;height: 60px;"></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" style="float: right;" id="navbarSupportedContent">


        <ul class="navbar-nav mr-auto" style="direction: rtl;">
            <li class="nav-item active" style="float: right;">
                <a mat-button (click)="logout()">تسجيل خروج <mat-icon>logout</mat-icon></a>
            </li>
        </ul>


        <!-- ml ==> right side , mr ==> left site -->
        <ul class="navbar-nav ml-auto" style="direction: rtl;">
            <li *ngIf="userType !='3'" class="nav-item active">
                <a mat-button routerLink="/home">الرئيسية<mat-icon>home</mat-icon></a>
            </li>
            <li *ngIf="userType == '5'" class="nav-item active">
                <a mat-button [matMenuTriggerFor]="menu">البيانات الاساسية <mat-icon>keyboard_arrow_down</mat-icon></a>
                <mat-menu #menu="matMenu">
                    <button routerLink="/projects" mat-menu-item>المشاريع</button>
                    <!-- <button routerLink="/units" mat-menu-item>الوحدات</button> -->
                    <!-- <button routerLink="/request-types" mat-menu-item>انواع الطلبات</button> -->
                    <!-- <button routerLink="/pipe-terms-types-list" mat-menu-item>انواع بنود الانابيب</button> -->
                    <button routerLink="/users" mat-menu-item>المستخدمين</button>
                </mat-menu>
            </li>


            <li class="nav-item active">
                <a mat-button [matMenuTriggerFor]="menu2">الطلبات <mat-icon>keyboard_arrow_down</mat-icon></a>
                <mat-menu #menu2="matMenu">
                    <button routerLink="/requests" mat-menu-item>عرض الطلبات</button>
                </mat-menu>
            </li>

            <li *ngIf="userType =='5' || userType=='2' || userType=='0'" class="nav-item active">
                <a mat-button [matMenuTriggerFor]="menu3">الاعمال <mat-icon>keyboard_arrow_down</mat-icon></a>
                <mat-menu #menu3="matMenu">
                    <button routerLink="/points-terms-entries-list" mat-menu-item>النقاط</button>
                    <button routerLink="/pipes-terms-entries-list" mat-menu-item>الانابيب</button>
                    <button routerLink="/rooms-entries-list" mat-menu-item>الغرف</button>
                </mat-menu>
            </li>




            <li *ngIf="userType !='3'" class="nav-item active">
                <a mat-button [matMenuTriggerFor]="menu4">الرسوم البيانية والتقارير <mat-icon>keyboard_arrow_down
                    </mat-icon></a>
                <mat-menu #menu4="matMenu">
                    <button *ngIf="userType !='3'" routerLink="/chart-request-list" mat-menu-item>الرسوم البيانية للطلبات</button>
                    <button *ngIf="userType !='3'" routerLink="/weekly-report-form" mat-menu-item>التقارير</button>
                </mat-menu>
            </li>




            <li *ngIf="userType =='5' || userType =='1' || userType=='0'" class="nav-item active">
                <a mat-button routerLink="/map">الخريطة <mat-icon>map</mat-icon></a>
            </li>

            <li *ngIf="userType =='5'" class="nav-item active">
                <a mat-button routerLink="/gdashboard-links">Data Studio Reports</a>
            </li>
        </ul>

        <div *ngIf="privileges.length > 0" class="dropdown">
            <button class="btn btn-secondary dropdown-toggle" type="button" id="privilegesDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Privileges
            </button>
            <div class="dropdown-menu" aria-labelledby="privilegesDropdown">
                <a class="dropdown-item" *ngFor="let privilege of privileges" (click)="selectRole(privilege)">
                    {{ privilegeRoles.get(privilege) }}
                </a>
            </div>
        </div>        
          
    </div>
</nav>