import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from 'src/app/services/notifications.service';
import { RepositoryService } from 'src/app/services/repository.service';
import { PointDivision } from 'src/app/_models/PointDivision';

@Component({
  selector: 'app-points-divisions-form',
  templateUrl: './points-divisions-form.component.html',
  styleUrls: ['./points-divisions-form.component.css']
})
export class PointsDivisionsFormComponent implements OnInit {

  //Vars ====================================
  pointDivisionForm: FormGroup;
  pointDivision: PointDivision;
  pointBuildingId: string;
  id: string;
  isUpdating: boolean = false;

  constructor(private router: Router,
    private repo: RepositoryService,
    private activeRoute: ActivatedRoute,
    public notificationsService: NotificationsService) { }
  ngOnInit(): void {
    this.createpointsBuildingForm();
    this.id = this.activeRoute.snapshot.params['id'];
    console.log(this.id);
    this.pointBuildingId = this.activeRoute.snapshot.params['pointBuildingId'];
    if (this.id != null) {
      this.isUpdating = true;
      this.pointDivisionGetById(this.id);
    }
  }

  //Form Create

  public createpointsBuildingForm() {
    this.pointDivisionForm = new FormGroup({
      id: new FormControl(null),
      name: new FormControl('', [Validators.required, Validators.maxLength(200)]),
    });
  }

  public onSubmit = (pointFormValue) => {
    if (this.pointDivisionForm.valid) {
      if (this.isUpdating) {
        this.pointDivisionUpdate(pointFormValue);
      }
      else {
        this.pointDivisionCreate(pointFormValue);
      }
    }
  }

  private pointDivisionCreate = (pointDivisionFormValue) => {
    let pointDivision: PointDivision = {
      name: pointDivisionFormValue.name,
      pointBuildingId: this.pointBuildingId,
    }
    console.log(pointDivision);
    this.repo.create('api/pointdivision/pointDivisionCreate', pointDivision)
      .subscribe(response => {
        this.notificationsService.success('تمت الاضافة بنجاح!');
        this.onCancel();
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }


  public pointDivisionGetById = (id: string) => {
    this.repo.getData('api/pointdivision/pointDivisionGetById?id=' + id).subscribe(response => {
      this.pointDivision = response as PointDivision
      // this.pointForm.patchValue(this.point);
      this.pointDivisionForm.get('id').setValue(this.pointDivision.id);
      this.pointDivisionForm.get('name').setValue(this.pointDivision.name);
      console.log(response);
    }, (error) => {
      console.log(error);
    })
  }


  public pointDivisionUpdate = (pointDivisionFormValue) => {
    let pointDivision: PointDivision = {
      id: pointDivisionFormValue.id,
      name: pointDivisionFormValue.name,
      pointBuildingId: this.pointBuildingId,
    }
    console.log(pointDivision);
    this.repo.update('api/pointdivision/pointDivisionUpdate', pointDivision)
      .subscribe(response => {
        this.notificationsService.success('تم التعديل بنجاح!');
        this.onCancel();
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }

  //Errors ======================================
  public hasError = (controlName: string, errorName: string) => {
    return this.pointDivisionForm.controls[controlName].hasError(errorName);
  }
  public onCancel = () => {
    this.router.navigate(['/points-divisions/' + this.pointBuildingId])
  }

}
