import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from 'src/app/services/notifications.service';
import { RepositoryService } from 'src/app/services/repository.service';
import { PipeTermsType } from './../../_models/PipeTermsType';
import { ProjectDropDownList } from './../../_models/ProjectDropDownList';

@Component({
  selector: 'app-pipe-terms-types-form',
  templateUrl: './pipe-terms-types-form.component.html',
  styleUrls: ['./pipe-terms-types-form.component.css']
})
export class PipeTermsTypesFormComponent implements OnInit {

  //Vars ====================================
  pipeTermsTypesForm: FormGroup;
  pipeTermsTypes: PipeTermsType;
  isUpdating: boolean = false;
  id: string;
  projectsList: ProjectDropDownList[];
  projectId: string;


  //Life cycle
  constructor(private router: Router,
    private repo: RepositoryService,
    private activeRoute: ActivatedRoute,
    public notificationsService: NotificationsService) { }

  ngOnInit(): void {
    this.projectId = this.activeRoute.snapshot.paramMap.get('projectId');

    this.projectGetDropDownList();
    this.id = this.activeRoute.snapshot.params['id'];
    // if (this.id != null) {
    //   this.isUpdating = true;
    //   this.pipeTermsTypesGetById(this.id);
    // }
  }


  //Form Create

  public createpipeTermsTypessForm() {
    this.pipeTermsTypesForm = new FormGroup({
      id: new FormControl(null),
      name: new FormControl('', [Validators.required, Validators.maxLength(200)]),
      project: new FormControl(this.projectsList[0].id)
    });
  }

  public onSubmit = (pipeTermsTypesFormValue) => {
    if (this.pipeTermsTypesForm.valid) {
      if (this.isUpdating) {
        this.pipeTermsTypesUpdate(pipeTermsTypesFormValue);
      }
      else {
        this.pipeTermsTypesCreate(pipeTermsTypesFormValue);
      }
    }
  }

  //Database ===================================
  public projectGetDropDownList = () => {
    this.repo.getData('api/projects/projectsGetDropDownList').subscribe(response => {
      this.projectsList = response as ProjectDropDownList[]
      this.createpipeTermsTypessForm();
      if (this.id != null) {
        this.isUpdating = true;
        this.pipeTermsTypesGetById(this.id);
      }
      console.log(response);
    }, (error) => {
      console.log(error);
    })
  }



  private pipeTermsTypesCreate = (pipeTermsTypesFormValue) => {
    let pipeTermsTypes: PipeTermsType = {
      name: pipeTermsTypesFormValue.name,
      projectId: this.projectId,
    }
    console.log(pipeTermsTypes);
    this.repo.create('api/pipeTermsTypess/pipeTermsTypesCreate', pipeTermsTypes)
      .subscribe(response => {
        this.notificationsService.success('تمت الاضافة بنجاح!');
        this.onCancel();
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }


  public pipeTermsTypesGetById = (id: string) => {
    this.repo.getData('api/pipeTermsTypess/pipeTermsTypesGetById?id=' + id).subscribe(response => {
      this.pipeTermsTypes = response as PipeTermsType
      // this.pipeTermsTypesForm.patchValue(this.pipeTermsTypes);
      this.pipeTermsTypesForm.get('id').setValue(this.pipeTermsTypes.id);
      this.pipeTermsTypesForm.get('name').setValue(this.pipeTermsTypes.name);
      this.pipeTermsTypesForm.get('project').setValue(this.projectId);
      console.log(response);
    }, (error) => {
      console.log(error);
    })
  }


  public pipeTermsTypesUpdate = (pipeTermsTypesFormValue) => {
    let pipeTermsTypes: PipeTermsType = {
      id: pipeTermsTypesFormValue.id,
      name: pipeTermsTypesFormValue.name,
      projectId: this.projectId,
    }
    console.log(pipeTermsTypes);
    this.repo.update('api/pipeTermsTypess/pipeTermsTypesUpdate', pipeTermsTypes)
      .subscribe(response => {
        this.notificationsService.success('تم التعديل بنجاح!');
        this.onCancel();
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }

  //Errors ======================================
  public hasError = (controlName: string, errorName: string) => {
    return this.pipeTermsTypesForm.controls[controlName].hasError(errorName);
  }
  public onCancel = () => {
    this.router.navigate(['/pipe-terms-types-list/'+this.projectId])
  }

}
