<mat-form-field>
    <input matInput disabled [matDatepicker]="dp1" [(ngModel)]="dateFrom" (dateChange)="changeFromDate($event.value)"
        placeholder="تاريخ التقديم من">
    <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
    <mat-datepicker #dp1 disabled="false"></mat-datepicker>
</mat-form-field>


<form [formGroup]="reportForm" *ngIf="reportForm" autocomplete="off" novalidate (ngSubmit)="onSubmit(reportForm.value)">

    <div class="row">
        <div class="col-md-12">
            <mat-form-field>
                <mat-label>اسم المشروع</mat-label>
                <mat-select formControlName="projectId" id="projectId">
                    <mat-option *ngFor="let project of projectsDropDownList" [value]="project.id">
                        {{project.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>


    <button mat-raised-button color="primary">
        <mat-icon>search</mat-icon>
        بحث
    </button>
</form>

<div class="row">
    <div class="center" class="col-md-12">
        <!-- <img class="center" src="/assets/123.PNG"> -->
        <iframe   frameBorder="0" [src]="urlSafe"></iframe>
    </div>
</div>

<!-- <iframe src="https://mansourcodes.com/"></iframe> -->
<!-- <iframe id="iframe" src="{{url}}"></iframe> -->


