import { PointBuilding } from './../../_models/PointBuilding';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RepositoryService } from 'src/app/services/repository.service';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-points-building-form',
  templateUrl: './points-building-form.component.html',
  styleUrls: ['./points-building-form.component.css']
})
export class PointsBuildingFormComponent implements OnInit {
//Vars ====================================
pointBuildingForm: FormGroup;
pointBuilding: PointBuilding;
pointId: string;
id: string;
isUpdating: boolean = false;

constructor(private router: Router,
  private repo: RepositoryService,
  private activeRoute: ActivatedRoute,
  public notificationsService: NotificationsService) { }
  ngOnInit(): void {
    this.createpointsBuildingForm();
    this.id = this.activeRoute.snapshot.params['id'];
    console.log(this.id);
    this.pointId = this.activeRoute.snapshot.params['pointId'];
    if (this.id != null) {
      this.isUpdating = true;
      this.pointBuildingGetById(this.id);
    }
  }

  //Form Create

  public createpointsBuildingForm() {
    this.pointBuildingForm = new FormGroup({
      id: new FormControl(null),
      name: new FormControl('', [Validators.required, Validators.maxLength(200)]),   
    });
  }

  public onSubmit = (pointFormValue) => {
    if (this.pointBuildingForm.valid) {
      if (this.isUpdating) {
        this.pointBuildingUpdate(pointFormValue);
      }
      else {
        this.pointBuildingCreate(pointFormValue);
      }
    }
  }

  private pointBuildingCreate = (pointBuildingFormValue) => {
    let point: PointBuilding = {
      name: pointBuildingFormValue.name,
      pointId: this.pointId,
    }
    console.log(point);
    this.repo.create('api/pointbuilding/PointBuildingCreate', point)
      .subscribe(response => {
        this.notificationsService.success('تمت الاضافة بنجاح!');
        this.onCancel();
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }


  public pointBuildingGetById = (id: string) => {
    this.repo.getData('api/pointbuilding/PointBuildingGetById?id=' + id).subscribe(response => {
      this.pointBuilding = response as PointBuilding
      // this.pointForm.patchValue(this.point);
      this.pointBuildingForm.get('id').setValue(this.pointBuilding.id);
      this.pointBuildingForm.get('name').setValue(this.pointBuilding.name);
      console.log(response);
    }, (error) => {
      console.log(error);
    })
  }


  public pointBuildingUpdate = (pointBuildingFormValue) => {
    let pointBuilding: PointBuilding = {
      id: pointBuildingFormValue.id,
      name: pointBuildingFormValue.name,
      pointId: this.pointId,
    }
    console.log(pointBuilding);
    this.repo.update('api/pointbuilding/PointBuildingUpdate', pointBuilding)
      .subscribe(response => {
        this.notificationsService.success('تم التعديل بنجاح!');
        this.onCancel();
        console.log(response);
      }, (error) => {
        console.log(error);
      })
  }

  //Errors ======================================
  public hasError = (controlName: string, errorName: string) => {
    return this.pointBuildingForm.controls[controlName].hasError(errorName);
  }
  public onCancel = () => {
    this.router.navigate(['/points-buildings/' + this.pointId])
  }

}
