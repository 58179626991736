import { UnitDropDownList } from './../../_models/UnitDropDownList';
import { Project } from './../../_models/Project';
import { UsersUnitsProjects } from './../../_models/UsersUnitsProjects';
import { User } from './../../_models/User';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgModel, Validators } from '@angular/forms';
import { Constants } from 'src/app/Constants';
import { ActivatedRoute, Router } from '@angular/router';
import { RepositoryService } from 'src/app/services/repository.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { ProjectDropDownList } from 'src/app/_models/ProjectDropDownList';

@Component({
  selector: 'app-users-form',
  templateUrl: './users-form.component.html',
  styleUrls: ['./users-form.component.css']
})
export class UsersFormComponent implements OnInit {

  //Vars ====================================
  userForm: FormGroup;
  user: User;
  isUpdating: boolean = false;
  id: string;
  projectsList: ProjectDropDownList[];
  unitsList: UnitDropDownList[];

  selectedProjects: ProjectDropDownList[] = [];
  selectedUnits: UnitDropDownList[] = [];

  selectedUserType: any;
  //enumuserTypes =  EnumuserType;
  enumuserTypes = Constants.enumUserTypes;

  constructor(private router: Router,
    private repo: RepositoryService,
    private activeRoute: ActivatedRoute,
    public notificationsService: NotificationsService) { }

  ngOnInit(): void {
    this.projectGetDropDownList();
    this.id = this.activeRoute.snapshot.params['id'];
    // if (this.id != null) {
    //   this.isUpdating = true;
    //   this.userGetById(this.id);
    // }
  }

  validateUserType(control: FormControl): { [s: string]: boolean } {
    const selectedTypes = control.value || [];
    const allowedCombinations = [
        ['0'], ['1'], ['2'], ['3'], ['4'], ['5'], 
        ['0', '1'], ['1', '2'], ['0', '2'], 
        ['0', '1', '2']
    ];

    if (selectedTypes.length > 1 && !allowedCombinations.some(combo => 
        combo.length === selectedTypes.length && combo.every(type => selectedTypes.includes(type)))) {
        return { 'invalidCombination': true };
    }
    return null;
}



  //Form Create

  public createusersForm() {
    console.log('createusersForm');
    this.userForm = new FormGroup({
      id: new FormControl(null),
      name: new FormControl('', [Validators.required, Validators.maxLength(200)]),
      userType: new FormControl([], [this.validateUserType]),
      username: new FormControl('', [Validators.required, Validators.maxLength(200)]),
      password: new FormControl('', [Validators.required, Validators.maxLength(200)]),
      projects: new FormControl(this.projectsList[0].id),
      units: new FormControl(this.unitsList[0].id),
    });
  }

  public onSubmit = (userFormValue) => {
    if (this.userForm.valid) {
      // Set userType in userFormValue to selectedUserType
      userFormValue.userType = this.selectedUserType;
  
      if (this.isUpdating) {
        this.userUpdate(userFormValue);
      } else {
        this.userCreate(userFormValue);
      }
    }
  }
  

  //Database ===================================

  public projectGetDropDownList = () => {
    console.log('projectGetDropDownList');
    this.repo.getData('api/projects/projectsGetDropDownList').subscribe(response => {
      this.projectsList = response as ProjectDropDownList[]
      this.unitsGetDropDownList()
    }, (error) => {
      console.log(error);
    })
  }


  public unitsGetDropDownList = () => {
    console.log('unitsGetDropDownList');
    this.repo.getData('api/units/unitsGetDropDownList').subscribe(response => {
      this.unitsList = response as UnitDropDownList[]
      this.createusersForm();
      if (this.id != null) {
        this.isUpdating = true;
        this.userGetById(this.id);
      }
    }, (error) => {
      console.log(error);
    })
  }


  public projectGetSelected = (userId: string) => {
    console.log('projectGetSelected');
    this.repo.getData('api/users-units-projects/projectsGetSelected?userId=' + userId).subscribe(response => {
      this.selectedProjects = response as ProjectDropDownList[];

      console.log(this.selectedProjects);
      this.userForm.get('projects').setValue(this.selectedProjects);
    }, (error) => {
      console.log(error);
    })
  }



  public unitGetSelected = (userId: string) => {
    console.log('unitGetSelected');
    this.repo.getData('api/users-units-projects/unitsGetSelected?userId=' + userId).subscribe(response => {
      this.selectedUnits = response as UnitDropDownList[];

      console.log(this.selectedUnits);
      this.userForm.get('units').setValue(this.selectedUnits);
    }, (error) => {
      console.log(error);
    })
  }


  private userCreate = (userFormValue) => {
    console.log('userCreate');
    let user: User = {
      name: userFormValue.name,
      userType: userFormValue.userType,
      username: userFormValue.username,
      password: userFormValue.password,
    }
    this.repo.create('api/users/userCreate', user)
      .subscribe(response => {
        //this.notificationsService.success('تمت الاضافة بنجاح!');
        let user: User = response as User;
        this.usersUnitsProjectsCreate(user, 'تمت الاضافة بنجاح');
      }, (error) => {
        console.log(error);
      })
  }


  private usersUnitsProjectsCreate(user: User, message: string) {
    console.log('usersUnitsProjectsCreate');
    var ids: number[] = [];
    //All but inspector
    if (this.selectedUserType != 2) {
      this.selectedProjects.forEach(project => {
        ids.push(Number(project.id));
      });
    }
    else {
      //Inspector Case
      this.selectedUnits.forEach(unit => {
        ids.push(Number(unit.id));
      });
    }

    let obj: UsersUnitsProjects = {
      userId: user.id,
      UserType: user.userType,
      ids: ids
    }
    this.repo.create('api/users-units-projects/create', obj)
      .subscribe(response => {
        this.notificationsService.success(message);
        this.onCancel();
      }, (error) => {
        console.log(error);
      })

  }



  onUserTypeChange(selectedTypes: string[]) {
    if (selectedTypes.includes('1') && selectedTypes.includes('0') && selectedTypes.includes('2')) {
        this.selectedUserType = '9';
    } else if (selectedTypes.includes('1') && selectedTypes.includes('0')) {
        this.selectedUserType = '6';
    } else if (selectedTypes.includes('1') && selectedTypes.includes('2')) {
        this.selectedUserType = '7';
    } else if (selectedTypes.includes('0') && selectedTypes.includes('2')) {
        this.selectedUserType = '8';
    } else {
        this.selectedUserType = selectedTypes[0] || null;
    }

    if (this.userForm.get('userType').hasError('invalidCombination')) {
      this.notificationsService.delete('مجموعة غير صحيحة!');
      this.userForm.get('userType').setValue([]);
    }
    console.log(this.selectedUserType)
  }

  public userGetById = (id: string) => {
    console.log('userGetById');
    this.repo.getData('api/users/userGetById?id=' + id).subscribe(response => {
      this.user = response as User

      this.userForm.get('id').setValue(this.user.id);
      this.userForm.get('name').setValue(this.user.name);
      this.userForm.get('username').setValue(this.user.username);
      this.userForm.get('password').setValue(this.user.password);
      this.userForm.get('userType').setValue(this.enumuserTypes[this.user.userType].value);

      this.selectedUserType = this.enumuserTypes[this.user.userType].value
      if (this.selectedUserType == 2) {
        this.unitGetSelected(this.user.id);
      }
      else {
        this.projectGetSelected(this.user.id);
      }

    }, (error) => {
      console.log(error);
    })
  }

  compareFunction(o1: any, o2: any) {
    return (o1.name == o2.name && o1.id == o2.id);
  }

  public userUpdate = (userFormValue) => {
    console.log('userUpdate');
    let user: User = {
      id: userFormValue.id,
      name: userFormValue.name,
      userType: userFormValue.userType,
      username: userFormValue.username,
      password: userFormValue.password,
    }
    console.log(user);
    this.repo.update('api/users/userUpdate', user)
      .subscribe(response => {
        let user: User = response as User;
        this.usersUnitsProjectsCreate(user, 'تم التعديل بنجاح')
      }, (error) => {
        console.log(error);
      })

  }







  //Errors ======================================
  public hasError = (controlName: string, errorName: string) => {
    return this.userForm.controls[controlName].hasError(errorName);
  }
  public onCancel = () => {
    this.router.navigate(['/users'])
  }
}
