<nav dir="ltr" aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="['/units']">الوحدات</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/points/'+unitIdForBreadCrumb]">النقاط</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/points-buildings/'+pointIdForBreadCrumb]">المباني</a></li>
        <li class="breadcrumb-item"><a [routerLink]="['/points-divisions/'+pointBuildingIdForBreadCrumb]">الاقسام</a></li>
        <li class="breadcrumb-item active" aria-current="page">البنود</li>
    </ol>
</nav>


<div class="gradient-Header">
    <div class="gradient-child">
        <h3>البنود</h3>
    </div>
</div>



<div fxLayout="row" fxLayoutAlign="space-around center">
    <mat-form-field>
        <input matInput (keydown.enter)="search(searchText)" [(ngModel)]="searchText" (keyup)="onKeyUp($event)"
            type="text" placeholder="بحث">
    </mat-form-field>


    <button mat-raised-button [disabled]="!searchText" (click)="search(searchText)" color="primary">
        <mat-icon>search</mat-icon>
        بحث
    </button>
</div>


<div class="row">
    <div class="col-md-3 mb-2">
        <button [routerLink]="['/points-terms-form/'+pointDivisionId]" mat-raised-button color="primary">
            <mat-icon>add</mat-icon>
            اضافة
        </button>
    </div>
</div>


<div class="mat-elevation-z5">
    <table mat-table [dataSource]="dataSource" matSort>
        <!-- Columns -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> اسم البند </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="pointDivisionName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> اسم القسم </th>
            <td mat-cell *matCellDef="let element"> {{element.pointDivisionName}} </td>
        </ng-container>

        <!-- Buttons -->
        <ng-container matColumnDef="update">
            <th mat-header-cell *matHeaderCellDef>تعديل</th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button color="accent" [routerLink]="['/points-terms-form/'+element.id+'/'+pointDivisionId]">
                    <mat-icon class="mat-18">edit</mat-icon>
                </button>
            </td>
        </ng-container>
        <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef>حذف</th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button color="warn" (click)="onDeleteClick(element.id)">
                    <mat-icon class="mat-18">delete</mat-icon>
                </button>
            </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns ; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>



    </table>

    <!-- Paginator -->
    <mat-paginator [length]="100" [pageSize]="5" showFirstLastButtons [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator>

    <!-- Loading Indicator -->
    <div *ngIf="isLoading"
        style="display: flex; justify-content: center; align-items: center; background: rgb(37, 104, 180);">
        <mat-progress-bar color="secondary" mode="indeterminate">
        </mat-progress-bar>
    </div>
</div>