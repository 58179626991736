import { PipeTermsEntries } from './../../_models/PipeTermsEntries';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { RepositoryService } from 'src/app/services/repository.service';
import { DatePipe } from '@angular/common';
import { DialogService } from 'src/app/services/dialog.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pipe-terms-entries-list',
  templateUrl: './pipe-terms-entries-list.component.html',
  styleUrls: ['./pipe-terms-entries-list.component.css']
})
export class PipeTermsEntriesListComponent implements OnInit {

  //Vars ==================================================
  isLoading = true;
  searchText: string;
  query: string;

  dateFrom: string;
  dateTo: string;
  dateSearchFrom: string;
  dateSearchTo: string;

  baseUrl = environment.urlAddress;

  //Table ==================================================
  public displayedColumns =
    [
      'lineName',
      'from',
      'to',
      'length',
      'pipeDivisionName',
      'pipeTermName',
      'pipeTermPrice',
      'pipeTermUnit',
      'amount',
      'price',   
      'creationDate',
      'note',
      'inspectorName',
      'photo', 'delete'
    ];

    


  public dataSource = new MatTableDataSource<PipeTermsEntries>();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private router: Router,
    private repo: RepositoryService,
    public datePipe: DatePipe,
    private activeRoute: ActivatedRoute,
    private confirmDialogService: DialogService,
    public notificationsService: NotificationsService) { }

  ngOnInit(): void {
    this.dateFrom = this.currentDate()
    this.dateTo = this.currentDate()

    this.dateSearchFrom = this.currentDate();
    this.dateSearchTo = this.currentDate();
    
    this.pipesTermsEntriesGet();

  }
  ngAfterViewInit(): void {
    this.applySortingAndPaging();
  }

  changeFromDate(event: any) {
    this.dateSearchFrom = this.datePipe.transform(event, "yyyy-MM-dd");
  }
  changeToDate(event: any) {
    this.dateSearchTo = this.datePipe.transform(event, "yyyy-MM-dd");
  }
  currentDate() {
    const currentDate = new Date();
    return currentDate.toISOString().split('T')[0];
  }

  //Database =================================================
  public pipesTermsEntriesGet = () => {
    this.isLoading = true;
    this.repo.getData('api/pipeTermEntries/pipeTermEntrieGet?dateFrom=' + this.dateSearchFrom + '&dateTo=' + this.dateSearchTo+ '&userType=' + localStorage.getItem('userType')+ '&inspectorId=' + localStorage.getItem('userId'))
      .subscribe(response => {
        this.dataSource.data = response as PipeTermsEntries[];
        console.log(response);
        this.isLoading = false;
      }, (error) => {
        console.log(error);
      })
  }


  public pipesTermEntriesSearch = (note: string) => {
    this.isLoading = true;
    if (note != null) {
      this.repo.getData('api/pipeTermEntries/pipeTermEntrieSearch?note=' + note + '&dateFrom=' + this.dateSearchFrom + '&dateTo=' + this.dateSearchTo + '&userType=' + localStorage.getItem('userType') + '&inspectorId=' + localStorage.getItem('userId'))
        .subscribe(response => {
          this.dataSource.data = response as PipeTermsEntries[];
          console.log(response);
          this.isLoading = false;
        }, (error) => {
          console.log(error);
        })
    }
  }

  public onDeleteClick = (id: string) => {
    const dialogRef = this.confirmDialogService.openConfirmDialog('هل انت متأكد من رغبتك في حذف الاعمال؟');
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.repo.delete('api/pipeTermEntries/pipeTermEntrieDelete?id=' + id).subscribe(response => {
          this.pipesTermsEntriesGet();
          this.notificationsService.delete('تم حذف الاعمال بنجاح');
        }, error => {
          console.log(error)
        })

      }
    });
  }



  //Helpers ===========================================
  applySortingAndPaging(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


  public search = (value: string) => {
    //this.dataSource.filter = value.trim().toLocaleLowerCase();
    console.log(value)
    if (value == null) { value = ''; }
    this.pipesTermEntriesSearch(value)
  }

  public clearSearch = (value: string) => {
    this.pipesTermsEntriesGet();
    this.dateFrom = this.currentDate()
    this.dateTo = this.currentDate()

    this.dateSearchFrom = this.currentDate();
    this.dateSearchTo = this.currentDate();
  }

}
