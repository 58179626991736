<!-- Loading Indicator -->
<div *ngIf="!linesCoordinatesForm"
    style="display: flex; justify-content: center; align-items: center; background: rgb(37, 104, 180);">
    <mat-progress-bar color="secondary" mode="indeterminate">
    </mat-progress-bar>
</div>

<div class="row">
    <div class="col-md-12">
        <mat-card>

            <mat-card-title>
                <div class="gradient-Header">
                    <div class="gradient-child">
                        <h3>اضافة احداثيات جديدة</h3>
                    </div>
                </div>

            </mat-card-title>
            <form [formGroup]="linesCoordinatesForm" *ngIf="linesCoordinatesForm" autocomplete="off" novalidate
                (ngSubmit)="onSubmit(linesCoordinatesForm.value)">
                <mat-card-content>
                    <input type="hidden" formControlName="id">

                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field>
                                <input matInput type="number" placeholder="Latitude" formControlName="latitude"
                                    id="latitude">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field>
                                <input matInput type="number" placeholder="الثابت" formControlName="constant"
                                    id="constant">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field>
                                <input matInput type="number" placeholder="Longitude" formControlName="longitude"
                                    id="longitude">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field>
                                <input matInput type="number" placeholder="الضغط" formControlName="pressure"
                                    id="latitude">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field>
                                <input matInput type="number" placeholder="القطر" formControlName="diameter"
                                    id="diameter">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field>
                                <input matInput type="text" placeholder="المحطة" formControlName="station" id="station">
                                <mat-error *ngIf="hasError('station', 'required')">هذا الحقل مطلوب</mat-error>
                                <mat-error *ngIf="hasError('station', 'maxlength')">الاسم اكثر من 200 حرف</mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                </mat-card-content>

                <mat-card-actions>


                    <div class="row">
                        <div class="col-md-12">
                            <button mat-raised-button color="primary" [disabled]="!linesCoordinatesForm.valid">
                                {{ isUpdating == true ? 'تعديل الاحداثي' : 'اضافة الاحداثي' }}
                            </button>
                        </div>
                    </div>

                    <div class="row" style="margin-top: 5px;">
                        <div class="col-md-12">
                            <button mat-flat-button color="secondary" (click)="onCancel()">عودة</button>
                        </div>
                    </div>
                </mat-card-actions>
            </form>
        </mat-card>

    </div>
</div>
